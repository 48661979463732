export type LabelValString = {
  label: string
  value: string
}

export type View = 'active' | 'onboarding' | 'inactive'

export const SENT_ONBOARDING_PROGRESSION_STATUS = {
  label: 'Sent Onboarding',
  value: 'sent_onboarding',
}

const ONBOARDING_STEPS = [
  {
    label: 'Onboarding Packet',
    value: 'onboarding_packet',
  },
  {
    label: 'Personal Info',
    value: 'personal_info',
  },
  {
    label: 'Background Check',
    value: 'background_check',
  },
  {
    label: 'Direct Deposit',
    value: 'direct_deposit',
  },
  {
    label: 'Active User',
    value: 'active_user',
  },
]

export const PROGRESSION_STATUSES = [
  {
    label: 'Initial List',
    value: 'initial_status',
  },
  {
    label: 'Initial Outreach',
    value: 'initial_outreach',
  },
  {
    label: 'Meeting Set',
    value: 'meeting_set',
  },
  {
    label: 'Meeting Complete',
    value: 'meeting_complete',
  },
  {
    label: 'Cultivate',
    value: 'pending_offer',
  },
  SENT_ONBOARDING_PROGRESSION_STATUS,
]

export const INACTIVE_PROGRESSION_STATUS = {
  label: 'Inactive',
  value: 'inactive',
}

export const VISIBLE_COLUMNS: Record<View, LabelValString[]> = {
  active: PROGRESSION_STATUSES,
  inactive: [INACTIVE_PROGRESSION_STATUS],
  onboarding: ONBOARDING_STEPS,
}

export const isOnboardingStatus = (status?: unknown) =>
  status === SENT_ONBOARDING_PROGRESSION_STATUS.value

export const POSITIONS = window.FEATURE_FLAGS.crew_updates
  ? [
      {
        label: 'Setter',
        value: 'setter',
      },
      {
        label: 'Closer',
        value: 'closer',
      },
      {
        label: 'Sales Manager',
        value: 'sales_manager',
      },
      {
        label: 'Sales Regional',
        value: 'regional_manager',
      },
      {
        label: 'Sales Divisional',
        value: 'divisional_manager',
      },
      {
        label: 'Sales VP',
        value: 'sales_vp',
      },
    ]
  : [
      {
        label: 'Setter',
        value: 'setter',
      },
      {
        label: 'Experienced Setter',
        value: 'experienced_setter',
      },
      {
        label: 'Closer',
        value: 'closer',
      },
      {
        label: 'Experienced Closer',
        value: 'experienced_closer',
      },
      {
        label: 'Self Gen',
        value: 'self_gen',
      },
      {
        label: 'Virtual Setter',
        value: 'virtual_setter',
      },
      {
        label: 'Experienced Virtual Setter',
        value: 'experienced_virtual_setter',
      },
      {
        label: 'Manager',
        value: 'sales_manager',
      },
      {
        label: 'Senior Manager',
        value: 'sr_sales_manager',
      },
      {
        label: 'Regional',
        value: 'regional',
      },
      {
        label: 'Senior Regional',
        value: 'sr_regional',
      },
    ]

export const REGIONS = [
  {
    label: 'Central',
    value: 'central',
  },
  {
    label: 'North West',
    value: 'northwest',
  },
  {
    label: 'South East',
    value: 'southeast',
  },
  {
    label: 'West',
    value: 'westcoast',
  },
]

export const DATE_FIELDS = ['last_contacted_at', 'onboarding_sent_at']

export const REQUIRED_FIELDS = [
  'progression_status',
  'position',
  'first_name',
  'last_name',
  'phone',
  'email',
  'stack_integrated',
]

export const ONBOARDING_REQUIRED_FIELDS = [
  'manager_user_id',
  'regional_manager_id',
  'recruited_by_user_id',
  'selling_state',
  'org_id',
]

export default function StatsIcon({ className }: { className?: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className={className}
    >
      <path
        d="M20 12V20"
        stroke="#3D3D3D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.7 14.67V20"
        stroke="#3D3D3D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4 17V20"
        stroke="#3D3D3D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.30005 13.6001V20.0001"
        stroke="#3D3D3D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.9393 8.50866C16.6228 9.19208 16.6228 10.3001 15.9393 10.9835C15.2559 11.6669 14.1479 11.6669 13.4645 10.9835C12.781 10.3001 12.781 9.19207 13.4645 8.50866C14.1479 7.82524 15.2559 7.82524 15.9393 8.50866"
        stroke="#3D3D3D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.3424 4.00561C22.0258 4.68902 22.0258 5.79707 21.3424 6.48048C20.659 7.16389 19.551 7.16389 18.8675 6.48048C18.1841 5.79706 18.1841 4.68902 18.8675 4.00561C19.551 3.32219 20.659 3.32219 21.3424 4.00561"
        stroke="#3D3D3D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.13245 9.40258C5.81587 10.086 5.81587 11.194 5.13245 11.8774C4.44903 12.5609 3.34099 12.5609 2.65758 11.8774C1.97417 11.194 1.97417 10.086 2.65758 9.40258C3.341 8.71916 4.44904 8.71916 5.13245 9.40258"
        stroke="#3D3D3D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.5365 4.90063C11.2199 5.58404 11.2199 6.69208 10.5365 7.37549C9.85309 8.05891 8.74505 8.05891 8.06164 7.37549C7.37822 6.69208 7.37822 5.58404 8.06164 4.90063C8.74505 4.21721 9.8531 4.21721 10.5365 4.90063"
        stroke="#3D3D3D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.77 6.35999L16.04 8.62999"
        stroke="#3D3D3D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.24 8.77999L10.75 7.10999"
        stroke="#3D3D3D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.23999 9.53001L7.95999 7.26001"
        stroke="#3D3D3D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

import NotificationDrawer from './NotificationDrawer'
import ChevronLeftIcon from '../../../icons/ChevronLeftIcon'

type MobileNotificationDrawerWrapperProps = {
  onBack?: () => void
}

const MobileNotificationDrawerWrapper = ({
  onBack,
}: MobileNotificationDrawerWrapperProps) => {
  return (
    <div data-testid="mobile-notification-drawer">
      <div className="flex items-center justify-start border-b-2 pb-4">
        <div
          onClick={() => onBack?.()}
          className="mx-4 flex h-6 w-6 items-center justify-center"
        >
          <ChevronLeftIcon />
        </div>
        <p className="text-2xl font-semibold">Notifications</p>
      </div>
      <NotificationDrawer />
    </div>
  )
}

export default MobileNotificationDrawerWrapper

import { GraphQLError } from 'graphql'
import { notifyCustomError } from './notify'
import axios from 'axios'

export function setupAxios() {
  axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
  if (window.ENV.CURRENT_USER_JWT) {
    axios.defaults.headers.common.Authorization = `Bearer ${window.ENV.CURRENT_USER_JWT}`
  }

  // As apollo server return 200 status code even for error,
  // this interceptor will catch gql error and turn it on Error that can handled by try/catch when using axios client
  axios.interceptors.response.use(
    function (response) {
      if (response.data.errors) {
        // will handle only apollo gql errors
        const error = response.data.errors[0]
        const customErrorCode = error.extensions?.code
        notifyCustomError(customErrorCode)
        return Promise.reject(new GraphQLError(error.message, error))
      }

      return response
    },
    function (error) {
      return Promise.reject(error)
    }
  )
}

import $ from 'jquery'

// This notify function can be used to notify Global errors that you need to handle
// You just need to create a event listner, and add the error on this enum

// CustomErrorCodes can be created on backend to apply specific behaviors on front-end
// Places that are currently using http status can be replaced with this
export enum CustomErrorCodes {
  PROJECT_LOCKED = 'PROJECT_LOCKED',
  NOT_FOUND = 'NOT_FOUND',
}

function notify(error: CustomErrorCodes) {
  $(document).trigger(error)
}

export function notifyCustomError(error: CustomErrorCodes) {
  if (error in CustomErrorCodes) {
    notify(error)
  }
}

import classNames from 'classnames'
import type { NavLinkProps } from './NavLink'
import NavLink from './NavLink'
import type { ApplicationName } from './utils'

interface AppButtonProps
  extends Omit<NavLinkProps<'button'>, 'icon' | 'children'> {
  name?: ApplicationName
  className?: string
  expanded?: boolean
  icon?: React.ReactNode
  titleIcon?: JSX.Element
  onClick?: () => void
  size?: 'small' | 'large'
}

export default function AppButton({
  name,
  className,
  expanded,
  titleIcon,
  icon,
  onClick,
  size = 'small',
  ...props
}: AppButtonProps) {
  const iconClassName =
    size === 'small'
      ? 'h-12 w-12 min-w-[3rem] bg-vader mr-1 text-2xl'
      : 'h-18 w-18 bg-gonk font-bold text-4xl'
  const textClassName =
    size === 'small' ? 'justify-between' : 'justify-center text-sm'
  return (
    <NavLink
      as="button"
      expanded={expanded}
      className={classNames('cursor-pointer', className)}
      onClick={onClick}
      iconElement={
        <div
          className={classNames(
            'flex items-center justify-center rounded-md text-center text-snowtrooper',
            iconClassName
          )}
        >
          {icon}
        </div>
      }
      {...props}
    >
      <div className={classNames('flex w-full items-center', textClassName)}>
        {name}
        {titleIcon}
      </div>
    </NavLink>
  )
}

export default function DashboardIcon({ className }: { className?: string }) {
  return (
    <svg
      width="25"
      height="39"
      viewBox="0 0 25 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M24.3333 3.49687L15.0045 9.78555V0H9.65931V9.78555L0.333313 3.49687V10.0073L7.50434 14.8404L0.333313 19.6734V26.1839L12.3263 18.0871L24.3333 26.2038V19.6933L17.1623 14.8603L24.3333 10.0073V3.49687ZM0.333313 38.8266H24.3333V33.4249H0.333313V38.8266Z"
        fill="white"
      />
    </svg>
  )
}

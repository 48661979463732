export default function DotIcon({ className }: { className?: string }) {
  return (
    <svg
      width="3"
      height="4"
      viewBox="0 0 3 4"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="1.5" cy="2" r="1.5" fill="#808080" />
    </svg>
  )
}

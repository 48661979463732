export default function SettingsIcon({ className }: { className: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      className={className}
    >
      <g id="Icon">
        <path
          id="Path"
          d="M21 9.5H19M3 9.5H5M7 22.5L10 3.5H14L17 22.5L7.78796 17.521L15.579 13.5L8.98523 9.93652M21 22.5H3M21 7H3"
          stroke="#3D3D3D"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  )
}

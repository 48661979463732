export default function RecruitsIcon({ className }: { className?: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.3292 20.9962H4.66322C3.74222 20.9962 2.99622 20.2502 2.99622 19.3292V7.66325C2.99622 6.74225 3.74222 5.99625 4.66322 5.99625H16.3302C17.2502 5.99625 17.9962 6.74225 17.9962 7.66325V19.3302C17.9962 20.2502 17.2502 20.9962 16.3292 20.9962Z"
        stroke="#323232"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.2641 10.1622C13.2401 11.1382 13.2401 12.7212 12.2641 13.6982C11.2881 14.6742 9.7051 14.6742 8.7281 13.6982C7.7511 12.7222 7.7521 11.1392 8.7281 10.1622C9.7041 9.18525 11.2871 9.18625 12.2641 10.1622"
        stroke="#323232"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.9961 18.4303C14.8651 18.1013 14.6621 17.8053 14.4021 17.5643V17.5643C13.9631 17.1563 13.3881 16.9303 12.7881 16.9303C11.7881 16.9303 9.20409 16.9303 8.20409 16.9303C7.60409 16.9303 7.03009 17.1573 6.59009 17.5643V17.5643C6.33009 17.8053 6.12709 18.1013 5.99609 18.4303"
        stroke="#323232"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.99609 5.99625V4.66325C5.99609 3.74225 6.74209 2.99625 7.66309 2.99625H19.3301C20.2501 2.99625 20.9961 3.74225 20.9961 4.66325V16.3302C20.9961 17.2502 20.2501 17.9962 19.3291 17.9962H17.9961"
        stroke="#323232"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default function Ufo() {
  return (
    <svg
      width="38"
      height="38"
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.8408 13.2021C31.6244 14.4535 34.8399 16.7978 34.8399 19.4906C34.8399 23.4981 27.7436 26.7295 19 26.7295C10.2564 26.7295 3.16007 23.4981 3.16007 19.4906C3.16007 16.7978 6.39141 14.4377 11.1909 13.1863"
        stroke="#808080"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.92 14.2879C26.92 16.2874 23.3741 17.9084 19 17.9084C14.6259 17.9084 11.08 16.2874 11.08 14.2879"
        stroke="#808080"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.92 14.2879V14.2317C26.92 12.1355 26.0873 10.1252 24.605 8.64299C23.1228 7.16077 21.1125 6.32806 19.0163 6.32806V6.32806C16.9201 6.32806 14.9098 7.16076 13.4276 8.64299C11.9453 10.1252 11.1126 12.1355 11.1126 14.2317V14.2879"
        stroke="#808080"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.8927 26.374L26.92 31.672"
        stroke="#808080"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.1073 26.374L11.08 31.672"
        stroke="#808080"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.0792 22.3889C19.0792 22.4326 19.0437 22.4681 19 22.4681C18.9563 22.4681 18.9208 22.4326 18.9208 22.3889C18.9208 22.3451 18.9563 22.3097 19 22.3097"
        stroke="#808080"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19 22.3097C19.0437 22.3097 19.0792 22.3451 19.0792 22.3889"
        stroke="#808080"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.1117 20.307C11.1117 20.3391 11.0924 20.368 11.0628 20.3802C11.0332 20.3925 10.9992 20.3857 10.9765 20.363C10.9539 20.3404 10.9471 20.3063 10.9593 20.2767C10.9716 20.2471 11.0005 20.2278 11.0325 20.2278"
        stroke="#808080"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.0325 20.2278C11.0763 20.2278 11.1117 20.2633 11.1117 20.307"
        stroke="#808080"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.0578 20.307C27.0578 20.3508 27.0223 20.3862 26.9786 20.3862C26.9348 20.3862 26.8994 20.3508 26.8994 20.307C26.8994 20.2633 26.9348 20.2278 26.9786 20.2278"
        stroke="#808080"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.9786 20.2278C27.0223 20.2278 27.0578 20.2633 27.0578 20.307"
        stroke="#808080"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default function LHXIcon({
  width = 36,
  height = 36,
  className,
}: {
  width?: number
  height?: number
  className?: string
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={width}
      height={height}
      className={className}
    >
      <path
        fill="#ffffff"
        strokeWidth={0}
        d="M24,9.51h-2.14s-2.66,1.78-2.66,1.78l-2.66-1.78h-3.45v1.89h-4.97v-1.89h-1.4v3.9H1.37v-3.9H0v4.99h8.11v-2.02h4.97v2.02h1.31,0s2.14,0,2.14,0l2.66-1.78,2.66,1.78h2.14l-3.73-2.49,3.73-2.49ZM14.55,14.31v-4.62l3.45,2.31-3.45,2.31Z"
      />
    </svg>
  )
}

import type { GetProjectsQuery } from '../../../generated/graphql'
import DotIcon from '../../icons/DotIcon'

export type ProjectResult = GetProjectsQuery['projects'][0]

function highlight(field: string, query: string) {
  const pos = field.toLowerCase().search(query.toLocaleLowerCase())

  if (pos < 0) return <span>{field}</span>

  return (
    <>
      <span>{field.substring(0, pos)}</span>
      <span className="bg-yellow-200">
        {field.substring(pos, pos + query.length)}
      </span>
      <span>{field.substring(pos + query.length)}</span>
    </>
  )
}

export default function AccountItem({
  account,
  query,
  showDelimiter,
  onClick,
  variant = 'standalone',
}: {
  account: ProjectResult
  query: string | null
  showDelimiter: boolean
  variant?: 'standalone' | 'select'
  onClick?: (p: ProjectResult) => void
}) {
  const fullName = `${account.primary_contact?.first_name} ${account.primary_contact?.last_name}`
  return (
    <>
      {showDelimiter && <div className="mx-3 h-[0.125rem] bg-atst"></div>}
      <a
        className="flex w-full flex-col gap-[0.375rem] p-3 hover:bg-hoth focus:bg-hoth"
        href={`/account/${account.id}?tab=sales-flow`}
        onClick={(e) => {
          if (onClick) {
            e.preventDefault()
            onClick(account)
          }
        }}
      >
        <span className="text-base text-gonk">
          {variant === 'standalone' ? (
            highlight(fullName, query ?? '')
          ) : (
            <span>{fullName}</span>
          )}
        </span>
        <span className="flex flex-row text-xs font-light leading-[1.125rem] text-deathstar">
          {variant === 'standalone' ? (
            <span>
              {highlight(account.location.street, query ?? '')},{' '}
              {account.location.city},{' '}
              {highlight(account.location.state, query ?? '')}{' '}
              {highlight(account.location.zip, query ?? '')}
            </span>
          ) : (
            <span>
              {account.location.street}, {account.location.city},{' '}
              {account.location.state} {account.location.zip}
            </span>
          )}

          <DotIcon className="my-auto mx-1.5 inline-block h-1 w-1" />
          <span>SID #{highlight(account.id.toString(), query ?? '')}</span>
          {account.public_id && (
            <>
              <DotIcon className="my-auto mx-1.5 inline-block h-1 w-1" />
              <span>
                ID {highlight(account.public_id.toString(), query ?? '')}
              </span>
            </>
          )}
        </span>
      </a>
    </>
  )
}

import { useFetchNotifications } from '@novu/notification-center'
import NotificationListItem from './NotificationListItem'
import { useEffect } from 'react'
import Spinner from '../../Spinner'
import { useInView } from 'react-intersection-observer'

const NotificationList = () => {
  const {
    data: notificationPages,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  } = useFetchNotifications()
  const { ref, inView } = useInView()

  useEffect(() => {
    if (!inView || !hasNextPage || isFetchingNextPage) return
    fetchNextPage()
  }, [inView, hasNextPage, fetchNextPage, isFetchingNextPage])
  if (!notificationPages) return null
  return (
    <div className="overflow-y-auto h-[calc(100vh-78px)]">
      {notificationPages.pages
        ?.map((page) => page.data)
        .flat()
        .map((notification) => (
          <NotificationListItem
            key={notification._id}
            notification={notification}
          />
        ))}
      <div ref={ref} className="flex items-center justify-center py-8">
        {hasNextPage && isFetchingNextPage ? (
          <Spinner size="tiny"></Spinner>
        ) : null}
      </div>
    </div>
  )
}

export default NotificationList

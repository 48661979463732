import { createContext, useState } from 'react'
import type { SetStateAction } from 'react'

const NavContext = createContext({
  addNewOpen: false,
  setAddNewOpen: (_: SetStateAction<boolean>) => {},
  leadCreationOpen: false,
  setLeadCreationOpen: (_: SetStateAction<boolean>) => {},
})

export function NavContextProvider({
  children,
}: {
  children: React.ReactNode
}) {
  const [addNewOpen, setAddNewOpen] = useState(false)
  const [leadCreationOpen, setLeadCreationOpen] = useState(
    new URLSearchParams(window.location.search).has('create_lead')
  )

  return (
    <NavContext.Provider
      value={{
        addNewOpen,
        setAddNewOpen,
        leadCreationOpen,
        setLeadCreationOpen,
      }}
    >
      {children}
    </NavContext.Provider>
  )
}

export default NavContext

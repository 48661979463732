import { DateTime } from 'luxon'
import { Effective_User_Role, type Recruit } from '../../generated/graphql'

export const stripPhoneNumber = (s: string | null | undefined) => {
  if (!s) return ''

  let striped = s.replace(/\D/g, '')
  striped = striped.substring(Math.max(0, striped.length - 10))

  return striped
}

type NullableString = string | undefined | null

export const formatPhoneNumber = (value: NullableString) => {
  const striped = stripPhoneNumber(value)

  let formatted

  if (striped.length > 3) {
    formatted = `(${striped.substring(0, 3)})`
  } else {
    return striped
  }

  if (striped.length > 6) {
    return (
      `${formatted} ${striped.substring(3, 6)} - ` +
      `${striped.substring(6, 10)}`
    )
  }

  return `${formatted} ${striped.substring(3)}`
}

export const formatDate = (
  epochMs?: number,
  dateStyle: 'full' | 'long' | 'medium' | 'short' = 'medium' as const
) =>
  epochMs
    ? new Date(epochMs).toLocaleDateString('en-US', {
        dateStyle,
      })
    : undefined

export const parseEpochToHtmlDate = (epochMs?: string | number) => {
  if (!epochMs) return undefined
  if (typeof epochMs === 'string') {
    epochMs = parseInt(epochMs, 10)
  }
  return DateTime.fromMillis(epochMs).toISODate()
}

export const recruitNameMatches = (
  recruit?: Pick<Recruit, 'first_name' | 'last_name'>,
  matchText?: string
) => {
  if (!recruit || !matchText) return true
  const firstName = recruit.first_name.trim().toLowerCase()
  const lastName = recruit.last_name.trim().toLowerCase()
  const matchTextParts = matchText.trim().toLowerCase().split(/\s+/)
  return matchTextParts.every(
    (matchText) => firstName.includes(matchText) || lastName.includes(matchText)
  )
}

export const formatYesNo = (value?: boolean | null) =>
  value === true ? 'Yes' : value === false ? 'No' : ''

export const UNSAVED_CHANGES_PROMPT =
  'You have unsaved changes, are you sure you want to leave?'

export const unBlockingAsyncAction = (fn: () => void) => {
  // The Promise.resolve().then block is to silent chrome warning
  // about click handler takes Xms. And to do it asynchronously
  return Promise.resolve().then(fn)
}

export const sizeToReadableString = (size: number) => {
  const units = ['B', 'KB', 'MB', 'GB', 'TB']
  let unitIndex = 0
  while (size >= 1024) {
    size /= 1024
    unitIndex++
  }
  const formattedSize = size.toFixed(2)
  if (formattedSize.endsWith('.00')) {
    return `${formattedSize.substring(0, formattedSize.length - 3)} ${
      units[unitIndex]
    }`
  }
  return `${formattedSize} ${units[unitIndex]}`
}

export function showUserPhoneNumber(role: string, currentUserId: number) {
  return (
    role === Effective_User_Role.SalesRep ||
    role === Effective_User_Role.SalesManager ||
    window.CURRENT_USER.id === currentUserId
  )
}

import type { AnnouncementListQuery } from '../../../generated/graphql'
import TimeAgoPosts from '../../components/TimeAgoPosts'
import TaskFileAttachment from '../Account/NewTasks/TaskDrawer/TaskFiles/TaskFileAttachment'
import parse from 'html-react-parser'

type AnnouncementItemProps = {
  announcement: AnnouncementListQuery['announcements'][number]
  headerContent?: React.ReactNode
  includeDivider?: boolean
}

export default function AnnouncementItem({
  announcement,
  headerContent,
  includeDivider = false,
}: AnnouncementItemProps) {
  return (
    <div key={announcement.id} className="flex flex-col gap-[0.375rem]">
      <div className="flex flex-wrap items-center justify-between">
        <div className="flex items-center gap-3">
          <h2 className="text-base font-semibold leading-7 text-black">
            {announcement.title}
          </h2>
          <div className="flex items-center gap-[0.375rem] text-xs font-normal text-deathstar">
            <span>•</span>
            <span>
              <TimeAgoPosts date={new Date(announcement.created_at)} />
            </span>
          </div>
        </div>
        {headerContent}
      </div>
      <div className="flex flex-col items-center gap-3">
        <div className="flex w-full flex-col gap-4 text-sm font-normal text-black">
          {parse(announcement.formatted_text)}
        </div>
        {announcement.attachment && (
          <TaskFileAttachment
            attachment={announcement.attachment}
            className="!h-auto !w-auto max-w-full"
            containerClassName="w-fit overflow-hidden rounded-lg bg-atat"
          />
        )}
      </div>
      {includeDivider ? (
        <hr className="mt-[1.125rem] border border-atst" />
      ) : null}
    </div>
  )
}

import { Squash as Hamburger } from 'hamburger-react'
import classNames from 'classnames'
import { useEffect, useState } from 'react'
import AppButton from './AppButton'
import { useLocation } from 'react-router-dom'
import {
  getAppName,
  LINKS,
  APPICONS,
  itemUrlMatchesPath,
  isItemVisible,
} from './utils'
import AppSwitcherMobile from './AppSwitcherMobile'
import NavLink from './NavLink'
import HelpChatIcon from '../../icons/HelpChatIcon'
import FeedbackIcon from '../../icons/FeedbackIcon'
import GlobalAddNew from '../GlobalAddNew/GlobalAddNew'
import SearchIcon from '../../icons/SearchIcon'
import { SearchAccountComponent } from '../SearchAccount'
import NotificationIcon from '../../icons/NotificationIcon'
import MobileNotificationDrawerWrapper from './Notifications/MobileNotificationDrawerWrapper'
import { NovuProvider } from '@novu/notification-center'
import { setNovuProps } from '.'

export default function MobileNavigation() {
  const [isExpanded, setIsExpanded] = useState(false)
  const [showDialog, setShowDialog] = useState(false)
  const [openComponent, setOpenComponent] = useState<
    'menu' | 'search' | 'notifications'
  >('menu')

  const { pathname } = useLocation()
  const appName = getAppName(pathname)

  const modifyDOMForOpenedDialog = () => {
    document.body.classList.remove('move-to-right')
    document.body.classList.add('move-to-left')
  }

  const modifyDOMForClosedDialog = () => {
    document.body.classList.remove('move-to-left')
    document.body.classList.add('move-to-right')
  }

  useEffect(() => {
    if (showDialog) {
      modifyDOMForOpenedDialog()
    } else {
      modifyDOMForClosedDialog()
    }
  }, [showDialog])

  const children = (
    <>
      <AppSwitcherMobile
        selectedAppName={appName}
        showDialog={showDialog}
        onClose={() => setShowDialog(false)}
      />
      <div
        data-testid="mobile-navigation"
        aria-expanded={isExpanded}
        className={classNames(
          'flex w-full flex-col overflow-hidden border-atst bg-snowtrooper !shadow-sm duration-200 ease-in',
          isExpanded ? 'h-screen' : 'h-[5.25rem]'
        )}
      >
        <div className="flex min-h-[5.25rem] w-full items-center justify-between !p-3">
          <div className="w-full">
            {appName && (
              <AppButton
                name={appName}
                expanded
                className="hover:!bg-transparent"
                icon={APPICONS[appName]}
                onClick={() => setShowDialog(true)}
                data-testid="mobile-navigation-app-button"
              />
            )}
          </div>
          <Hamburger
            size={16}
            toggled={isExpanded}
            toggle={setIsExpanded}
            label="expand navigation"
          />
        </div>
        {openComponent === 'search' && (
          <SearchAccountComponent
            onCancelSearch={() => setOpenComponent('menu')}
          />
        )}
        {openComponent === 'notifications' && (
          <MobileNotificationDrawerWrapper
            onBack={() => setOpenComponent('menu')}
          />
        )}
        {openComponent === 'menu' && (
          <div
            data-testid="body-of-mobile-navigation"
            className={classNames(
              'flex flex-col gap-1.5 overflow-hidden border-t-2 border-atst'
            )}
          >
            <div
              data-testid="links-of-mobile-navigation"
              className="flex h-full flex-col gap-1.5 overflow-auto !px-3 !py-1"
            >
              {LINKS[appName].items.filter(isItemVisible).map((page) => (
                <NavLink
                  href={page.url}
                  key={page.label}
                  className="flex h-[3.75rem] flex-row items-center gap-1 rounded-md hover:bg-atst"
                  iconElement={page.icon}
                  expanded
                  selected={itemUrlMatchesPath(page, pathname)}
                >
                  {page.label}
                </NavLink>
              ))}
            </div>
            <div
              data-testid="bottom-of-mobile-navigation"
              className={`flex w-full flex-col gap-1.5 ${
                LINKS[appName].items[0] && 'border-t-2 border-atst'
              } px-3 py-4`}
            >
              <GlobalAddNew
                expanded
                isMobile
                onClick={() => setIsExpanded(false)}
              />
              <NavLink
                onClick={() => setOpenComponent('search')}
                as="button"
                icon={SearchIcon}
                className="h-[3.75rem] min-w-[3.75rem] items-center"
                expanded
                data-testid="mobile-search-button"
                data-tip="Search"
                data-place="right"
                data-effect="solid"
                data-class="bg-snowtrooper p-2 rounded-md opacity-100 shadow-sm"
              >
                Search
              </NavLink>
              {window.ENV.NOVU_APP_ID ? (
                <NavLink
                  onClick={() => setOpenComponent('notifications')}
                  as="button"
                  data-testid="mobile-notifications-button"
                  className="h-[3.75rem] items-center"
                  icon={NotificationIcon}
                  expanded
                >
                  Notifications
                </NavLink>
              ) : null}
              <NavLink
                as="button"
                data-testid="open-intercom"
                className="open-intercom h-[3.75rem] items-center"
                id="zoom-chat-mobile"
                icon={HelpChatIcon}
                expanded
              >
                Help
              </NavLink>
              <NavLink
                href="https://solflow.canny.io/feedback"
                target="_blank"
                rel="noreferrer"
                className="h-[3.75rem] items-center"
                icon={FeedbackIcon}
                expanded
              >
                Feedback
              </NavLink>
            </div>
          </div>
        )}
      </div>
    </>
  )

  return (
    <>
      {window.ENV.NOVU_APP_ID ? (
        <NovuProvider
          data-testid="novu-provider"
          subscriberId={window.CURRENT_USER.id.toString()}
          applicationIdentifier={window.ENV.NOVU_APP_ID}
          {...setNovuProps()}
          initialFetchingStrategy={{
            fetchUnseenCount: true,
            fetchNotifications: true,
          }}
        >
          {children}
        </NovuProvider>
      ) : (
        children
      )}
    </>
  )
}

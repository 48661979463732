export default function MedalIcon({ className }: { className?: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className={className}
    >
      <path
        d="M11.999 14.023L13.754 14.945C14.096 15.125 14.495 14.834 14.43 14.454L14.095 12.5L15.515 11.117C15.792 10.848 15.639 10.378 15.257 10.323L13.295 10.038L12.418 8.25999C12.247 7.91399 11.753 7.91399 11.583 8.25999L10.706 10.039L8.74405 10.324C8.36205 10.38 8.20905 10.849 8.48605 11.118L9.90605 12.501L9.57005 14.455C9.50505 14.835 9.90405 15.125 10.246 14.946L12.001 14.024"
        stroke="#323232"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.32503 14.7319C2.01902 14.1189 2.84503 13.7949 3.71202 13.7949C4.56903 13.7949 5.38702 14.1119 6.07702 14.7119C6.40403 14.9959 6.59203 15.4069 6.59403 15.8389C6.59602 16.2719 6.41103 16.6839 6.08703 16.9699C5.39303 17.5829 4.56803 17.9069 3.70103 17.9069C2.84403 17.9069 2.02602 17.5899 1.33503 16.9899C1.00803 16.7059 0.820025 16.2949 0.818025 15.8629C0.816025 15.4299 1.00103 15.0179 1.32503 14.7319ZM5.09503 15.8449C4.67302 15.4779 4.19202 15.2949 3.71203 15.2949C3.22703 15.2949 2.74203 15.4819 2.31802 15.8559C2.74003 16.2229 3.22102 16.4059 3.70103 16.4059C4.18603 16.4059 4.67103 16.2189 5.09503 15.8449Z"
        fill="#323232"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.96397 7.41182C1.24497 7.13882 1.62097 6.98682 2.00997 6.98682C2.04997 6.98682 2.08997 6.98782 2.12997 6.99182C4.02797 7.14282 5.37097 8.52282 5.47197 10.4248C5.49397 10.8568 5.32997 11.2768 5.01997 11.5788C4.73897 11.8518 4.36297 12.0038 3.97397 12.0038C3.93397 12.0038 3.89397 12.0018 3.85397 11.9988C1.95597 11.8468 0.61297 10.4678 0.51197 8.56582C0.48997 8.13382 0.65397 7.71382 0.96397 7.41182ZM2.00997 8.48682C3.14597 8.57782 3.91297 9.36582 3.97397 10.5038C2.83797 10.4128 2.07097 9.62482 2.00997 8.48682Z"
        fill="#323232"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.18001 1.82292C5.46501 1.50092 5.87501 1.31592 6.30501 1.31592H6.31101C6.74301 1.31792 7.15401 1.50592 7.43801 1.83392C8.70601 3.29392 8.69801 5.24992 7.41801 6.69992C7.13301 7.02192 6.72301 7.20692 6.29301 7.20692H6.28701C5.85401 7.20492 5.44401 7.01692 5.16001 6.68992C3.89201 5.22992 3.90001 3.27392 5.18001 1.82292ZM6.30501 2.81592C7.07201 3.69992 7.06701 4.82892 6.29301 5.70692C5.52601 4.82292 5.53001 3.69392 6.30501 2.81592Z"
        fill="#323232"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.887 18.173C14.17 17.888 14.553 17.729 14.952 17.729C14.983 17.729 15.014 17.73 15.045 17.732C16.931 17.849 18.287 19.194 18.42 21.078C18.451 21.509 18.294 21.933 17.989 22.24C17.706 22.525 17.323 22.684 16.924 22.684C16.893 22.684 16.862 22.683 16.831 22.681C14.945 22.564 13.589 21.219 13.456 19.335C13.425 18.904 13.582 18.48 13.887 18.173ZM14.952 19.229C16.074 19.299 16.844 20.063 16.923 21.184C15.801 21.114 15.031 20.35 14.952 19.229Z"
        fill="#323232"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.9219 14.7119C18.6129 14.1119 19.4309 13.7949 20.2879 13.7949C21.1549 13.7949 21.9799 14.1189 22.6739 14.7319C22.9979 15.0179 23.1829 15.4309 23.1809 15.8629C23.1789 16.2959 22.9909 16.7069 22.6639 16.9899C21.9729 17.5899 21.1549 17.9069 20.2979 17.9069C19.4309 17.9069 18.6059 17.5829 17.9119 16.9699C17.5879 16.6839 17.4029 16.2709 17.4049 15.8389C17.4069 15.4059 17.5949 14.9949 17.9219 14.7119ZM21.6819 15.8559C21.2579 15.4819 20.7729 15.2949 20.2879 15.2949C19.8079 15.2949 19.3269 15.4779 18.9049 15.8449C19.3289 16.2189 19.8139 16.4059 20.2989 16.4059C20.7789 16.4059 21.2599 16.2219 21.6819 15.8559Z"
        fill="#323232"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.87 6.99182C21.91 6.98882 21.95 6.98682 21.99 6.98682C22.379 6.98682 22.755 7.13782 23.036 7.41182C23.346 7.71282 23.511 8.13382 23.488 8.56582C23.387 10.4678 22.044 11.8468 20.146 11.9988C20.106 12.0018 20.066 12.0038 20.026 12.0038C19.637 12.0038 19.261 11.8528 18.98 11.5788C18.67 11.2778 18.505 10.8568 18.528 10.4248C18.629 8.52282 19.972 7.14382 21.87 6.99182ZM21.99 8.48682C21.93 9.62482 21.162 10.4128 20.026 10.5038C20.086 9.36582 20.854 8.57782 21.99 8.48682Z"
        fill="#323232"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.689 1.31592H17.695C18.125 1.31592 18.535 1.50092 18.82 1.82292C20.1 3.27292 20.108 5.22792 18.84 6.68892C18.556 7.01592 18.145 7.20392 17.713 7.20592H17.707C17.276 7.20592 16.867 7.02192 16.582 6.69892C15.302 5.24892 15.294 3.29392 16.562 1.83292C16.846 1.50592 17.257 1.31792 17.689 1.31592ZM17.695 2.81592C18.469 3.69292 18.474 4.82292 17.707 5.70592C16.933 4.82892 16.928 3.69992 17.695 2.81592Z"
        fill="#323232"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.95505 17.732C8.98605 17.73 9.01705 17.729 9.04805 17.729C9.44705 17.729 9.83105 17.888 10.113 18.173C10.417 18.48 10.574 18.904 10.544 19.335C10.41 21.219 9.05405 22.564 7.16905 22.681C7.13805 22.683 7.10705 22.684 7.07605 22.684C6.67705 22.684 6.29305 22.525 6.01105 22.24C5.70705 21.933 5.55005 21.509 5.58005 21.078C5.71405 19.194 7.07005 17.849 8.95505 17.732ZM9.04805 19.229C8.96905 20.35 8.19905 21.114 7.07705 21.184C7.15605 20.062 7.92605 19.299 9.04805 19.229Z"
        fill="#323232"
      />
    </svg>
  )
}

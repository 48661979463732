import { CheckIcon } from '@heroicons/react/outline'
import classNames from 'classnames'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import AppButton from './AppButton'
import VerticalChevronsIcon from '../../icons/VerticalChevronsIcon'
import type { ApplicationName } from './utils'
import {
  APPS,
  LINKS,
  APPICONS,
  isAppVisible,
  isItemVisible,
  itemUrlMatchesPath,
} from './utils'
import NavLink from './NavLink'
import ReactTooltip from 'react-tooltip'

type Props = {
  selected: ApplicationName
  expanded?: boolean
}

function AppList({ selected, expanded }: Props) {
  const optionClasses = (isSelected: boolean) =>
    classNames(
      'flex h-[3.75rem] w-full cursor-pointer flex-row items-center hover:bg-atst !px-3 !rounded-lg',
      isSelected ? 'bg-atst' : ''
    )

  const options = APPS.filter(isAppVisible).map((app) => (
    <a
      className={optionClasses(app === selected)}
      href={LINKS[app].landingPage}
      key={app}
    >
      <AppButton
        name={app}
        data-testid={`appswitcher-${app}`}
        expanded
        icon={APPICONS[app]}
        titleIcon={
          app === selected ? (
            <CheckIcon className="my-auto h-full w-8 flex-none stroke-gonk stroke-1" />
          ) : undefined
        }
      />
    </a>
  ))

  const classes = classNames(
    'absolute z-20 flex h-fit w-72 flex-col rounded-md bg-snowtrooper shadow-md !p-3 gap-1.5 -top-2 transition-all duration-200 ease-in',
    expanded ? 'left-[17.5rem]' : 'left-20'
  )

  return <div className={classes}>{options}</div>
}

export default function AppSwitcher({ selected, expanded }: Props) {
  const [open, setOpen] = useState(false)
  const { pathname } = useLocation()
  const items = LINKS[selected].items.filter(isItemVisible).map((page) => (
    <NavLink
      data-tip={page.label}
      data-place="right"
      data-effect="solid"
      data-class="bg-snowtrooper p-2 rounded-md opacity-100 shadow-sm"
      href={page.url}
      key={page.label}
      iconElement={page.icon}
      expanded={expanded}
      selected={itemUrlMatchesPath(page, pathname)}
      className="h-[3.75rem] min-w-[3.75rem] items-center pl-[0.375rem]"
    >
      {page.label}
    </NavLink>
  ))

  const appSwitcherRef = useRef<HTMLDivElement>(null)

  const close = useCallback(
    (e: any) => {
      if (!appSwitcherRef.current) return
      if (appSwitcherRef.current.contains(e.target)) return

      setOpen(false)
    },
    [setOpen]
  )

  useEffect(() => {
    if (!open) return
    document.addEventListener('click', close)
    return () => document.removeEventListener('click', close)
  }, [open, close])

  return (
    <div
      className="relative flex flex-col gap-1.5 pl-[0.125rem]"
      ref={appSwitcherRef}
    >
      {!expanded && <ReactTooltip type="light" />}
      <AppButton
        name={selected}
        className={classNames(
          'h-[3.75rem] min-w-[3.75rem] items-center border-4 border-transparent pl-[0.125rem]',
          open ? 'rounded-md !border-mando' : undefined
        )}
        expanded={expanded}
        onClick={() => setOpen(!open)}
        icon={APPICONS[selected]}
        titleIcon={
          expanded ? <VerticalChevronsIcon className="my-auto" /> : undefined
        }
        data-testid="app-switcher-btn"
      />

      {open && <AppList expanded={expanded} selected={selected} />}
      <div className="flex flex-col gap-1.5">{items}</div>
    </div>
  )
}

export default function SettingsIcon({ className }: { className: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      className={className}
    >
      <g id="Group">
        <path
          id="Path"
          d="M11 3.5H8C5.23858 3.5 3 5.73858 3 8.5V16.5C3 19.2614 5.23858 21.5 8 21.5H16C18.7614 21.5 21 19.2614 21 16.5V12.5"
          stroke="#323232"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Path_2"
          d="M17.501 5.75001C17.6389 5.75056 17.7504 5.86273 17.75 6.00067C17.7496 6.13861 17.6376 6.25019 17.4997 6.25C17.3617 6.24982 17.25 6.13795 17.25 6.00001C17.2496 5.93342 17.276 5.86947 17.3232 5.82248C17.3704 5.77549 17.4344 5.74938 17.501 5.75001"
          stroke="#323232"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Path_3"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21 6V6C21 4.067 19.433 2.5 17.5 2.5V2.5C15.567 2.5 14 4.067 14 6V6C14 7.63851 15.788 9.38882 16.808 10.2544C17.2108 10.5819 17.7882 10.5819 18.191 10.2544C19.212 9.38881 21 7.63851 21 6Z"
          stroke="#323232"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Path_4"
          d="M4 5.5L19 20.5"
          stroke="#323232"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Path_5"
          d="M4 19.5L11 12.5"
          stroke="#323232"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  )
}

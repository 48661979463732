import type { UserContext } from './react/components/types'

export default function loadIntercom(user: UserContext) {
  window.intercomSettings = {
    app_id: 'j9d38f3x',
    name: `${user.first_name} ${user.last_name}`,
    email: user.email,
    custom_launcher_selector: '.open-intercom',
  }
  ;(function () {
    const w = window
    const ic = w.Intercom
    if (typeof ic === 'function') {
      ic('reattach_activator')
      ic('update', w.intercomSettings)
    } else {
      const d = document
      const i = function (...args: any[]) {
        i.c(args)
      }
      i.q = [] as any[]
      i.c = function (args: any[]) {
        i.q.push(args)
      }
      w.Intercom = i
      const l = function () {
        const s = d.createElement('script')
        s.type = 'text/javascript'
        s.async = true
        s.src = 'https://widget.intercom.io/widget/j9d38f3x'
        const x = d.getElementsByTagName('script')[0]
        x.parentNode?.insertBefore(s, x)
      }
      if (document.readyState === 'complete') {
        l()
      } else {
        w.addEventListener('load', l, false)
      }
    }
  })()

  window.Intercom('update', {
    hide_default_launcher: true,
  })
}

import { createPortal } from 'react-dom'

/**
 * Use React's portal stuff to render a component in a different place in the DOM.
 * @param container The element to render the children in, if it's null, nothing will be rendered.
 * @param children The children to render.
 * @example
 * // in jsx anywhere
 * <>
 *   This text is part of a react element tree somewhere else in the dom
 *  <Portal container={document.getElementById('foobar')}>
 *    <div>Will be rendered into foobar, but only if `foobar` exists</div>
 *  </Portal>
 * </>

 */
export default function Portal({
  container,
  children,
}: {
  container?: HTMLElement | null
  children: React.ReactNode
}) {
  if (!container) return null
  return createPortal(children, container)
}

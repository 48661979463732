import type { DisplayNameFields } from '../../helpers/view-helpers'
import { displayName, stringToColor } from '../../helpers/view-helpers'
import type {
  DisplayNameWithAvatarFragment,
  Maybe,
} from '../../generated/graphql'

/**
 * If you need to add a new size, add it to the `avatarSizes` object below
 * and add the classnames you need to to make it look good at that size.
 */
const avatarSizes = {
  '24': {
    containerClasses: 'h-24 w-24',
    textClasses: 'text-4xl',
  },
  '18': {
    containerClasses: 'h-18 w-18',
    textClasses: 'text-2xl',
  },
  '12': {
    containerClasses: 'h-12 w-12',
    textClasses: 'text-lg',
  },
  '6': {
    containerClasses: 'h-6 w-6',
    textClasses: 'text-[0.5rem]',
  },
  '5': {
    containerClasses: 'h-5 w-5',
    textClasses: 'text-[0.475rem] tracking-tight',
  },
} as const

/**
 * Displays a user's avatar, or their initials if they don't have one.
 *
 * Whenever you use this, you should use the `...DisplayNameWithAvatar` graphql
 * fragment to get the data you need.
 */
export default function Avatar({
  user,
  size = '24',
}: {
  user: DisplayNameFields & {
    avatar_attachment?: Maybe<
      Omit<
        NonNullable<DisplayNameWithAvatarFragment['avatar_attachment']>,
        'id' | '__typename'
      >
    >
  }
  size?: keyof typeof avatarSizes
}) {
  const name = displayName(user)
  if (user.avatar_attachment) {
    return (
      <img
        className={`inline-block ${avatarSizes[size].containerClasses} rounded-full`}
        alt={`avatar for: ${name}`}
        src={user.avatar_attachment.url}
        data-testid="avatar-image"
      />
    )
  } else {
    const [firstInitial, ...others] = name.split(' ').map((w) => w[0])
    const initialsToDisplay = [firstInitial, others[others.length - 1]]
      .filter(Boolean)
      .join(' ')

    return (
      <span
        className={`inline-flex ${avatarSizes[size].containerClasses} shrink-0 items-center justify-center rounded-full`}
        style={{ backgroundColor: stringToColor(name) }}
        title={name}
        data-testid="avatar-initials"
      >
        <span
          className={`${avatarSizes[size].textClasses} font-medium leading-none text-white`}
        >
          {initialsToDisplay}
        </span>
      </span>
    )
  }
}

export default function ChecklistIcon({
  className,
  color = '#3D3D3D',
}: {
  className?: string
  color?: string
}) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M11 5H21"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11 12H21"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11 19H21"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.01999 18.508L4.67299 19.996L7.99999 17"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.41422 3.58579C7.19527 4.36684 7.19527 5.63317 6.41422 6.41422C5.63317 7.19527 4.36684 7.19527 3.58579 6.41422C2.80474 5.63317 2.80474 4.36684 3.58579 3.58579C4.36684 2.80474 5.63317 2.80474 6.41422 3.58579"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.41422 10.5858C7.19527 11.3668 7.19527 12.6332 6.41422 13.4142C5.63317 14.1953 4.36684 14.1953 3.58579 13.4142C2.80474 12.6332 2.80474 11.3668 3.58579 10.5858C4.36684 9.80474 5.63317 9.80474 6.41422 10.5858"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

import classNames from 'classnames'

export type NavLinkProps<T extends React.ElementType> = {
  as?: T
  icon?: React.ElementType
  iconElement?: React.ReactElement
  children: React.ReactNode
  useChildWrapper?: boolean
  className?: string
  expanded?: boolean
  selected?: boolean
  disabled?: boolean
  selectedClassName?: string
  hideOverflow?: boolean
  iconClasses?: string
  hoverClasses?: string
} & React.ComponentPropsWithoutRef<T>

export default function NavLink<T extends React.ElementType = 'a'>({
  as,
  icon: Icon,
  iconElement,
  children,
  useChildWrapper = true,
  className,
  expanded,
  selected = false,
  disabled = false,
  selectedClassName = 'bg-atst',
  hideOverflow = true,
  iconClasses = '',
  hoverClasses = 'hover:bg-atst',
  ...props
}: NavLinkProps<T>) {
  const NavLink = as || 'a'
  return (
    <NavLink
      className={classNames(
        'flex flex-row gap-1 whitespace-nowrap rounded-md text-left',
        hideOverflow && 'overflow-hidden',
        !disabled && hoverClasses,
        expanded ? 'w-full' : 'w-12',
        selected && selectedClassName,
        className
      )}
      {...props}
    >
      {iconElement}
      {Icon && (
        <Icon
          data-testid="icon-for-navlink"
          className={
            iconClasses ||
            'h-12 min-h-[3rem] w-12 min-w-[3rem] stroke-vader p-2.5'
          }
        />
      )}
      {expanded && (
        <>
          {useChildWrapper ? (
            <span
              data-testid="children-for-navlink"
              className="my-auto w-full p-1 text-left text-base"
            >
              {children}
            </span>
          ) : (
            children
          )}
        </>
      )}
    </NavLink>
  )
}

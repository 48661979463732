import { Effective_User_Role, Organization_Type } from '../../generated/graphql'

export { Effective_User_Role as Role } from '../../generated/graphql'

export const hasRole = (role: Effective_User_Role) =>
  !!window.CURRENT_USER.effective_roles[role]

const companySettings = () => currentUser().organization?.company_settings

export const canViewLumioStore = () => {
  if (!window.FEATURE_FLAGS.dealer_integration) return true

  return !!companySettings()?.show_lumio_store
}

export const canViewAccountMap = () => {
  if (!window.FEATURE_FLAGS.dealer_integration) return true

  return !!companySettings()?.show_map
}

export const canViewCrew = () => {
  if (!window.FEATURE_FLAGS.dealer_integration) return true

  return !!companySettings()?.show_crew
}

export const canViewCompetitions = () => {
  if (!window.FEATURE_FLAGS.dealer_integration) return true

  return !!companySettings()?.show_competitions
}

export const canViewMedia = () => {
  if (!window.FEATURE_FLAGS.dealer_integration) return true

  return !!companySettings()?.show_media
}

export const canViewWorkdayElements = () => {
  if (!window.FEATURE_FLAGS.dealer_integration) return true

  return !!companySettings()?.show_workday_elements
}

export const canViewAdminPage = () => {
  return (
    hasRole(Effective_User_Role.Superadmin) &&
    currentUser().organization?.type === Organization_Type.Admin
  )
}

export const canViewOpsPage = () => {
  return hasRole(Effective_User_Role.OpsEmployee)
}

const currentUser = () => window.CURRENT_USER
export default currentUser

import { XIcon } from '@heroicons/react/outline'
import { useTaskFilesQuery } from '../../../../../../generated/graphql'
import { sizeToReadableString } from '../../../../../utils'
import TaskFileAttachment from './TaskFileAttachment'
import { Suspense, lazy, useState } from 'react'

const DeleteAttachmentModal = lazy(
  () => import('../../../FilesTab/DeleteAttachmentModal')
)

export type TaskSummaryCaseNote = {
  __typename?: 'CaseNote' | undefined
  id: number
  body?: string | null | undefined
  created_at: number
  created_by: string
}

type TaskFilesProps = {
  taskId?: number
  setShouldCloseDrawerOnBlur: (shouldClose: boolean) => void
}

export const getFileExtensionFromUrl = (url: string) => {
  return /(?:\.([^.]+))?$/.exec(new URL(url).pathname)?.[1]
}

const getFileName = (name: string, url: string) => {
  if (/(?:\.([^.]+))?$/.exec(name)?.[1]) {
    return name
  }
  return `${name}.${getFileExtensionFromUrl(url)}`
}

const TaskFiles = ({ taskId }: TaskFilesProps) => {
  const [{ data: getFilesData }] = useTaskFilesQuery({
    variables: {
      id: taskId,
    },
    requestPolicy: 'network-only',
    pause: !taskId,
  })
  const [selectedAttachmentId, setSelectedAttachmentId] = useState<number>()
  const [showDeleteAttachmentModal, setShowDeleteAttachmentModal] =
    useState(false)

  const taskWithAttachments = getFilesData?.tasks?.[0]
  const attachments = taskWithAttachments?.attachments ?? []

  return (
    <>
      {showDeleteAttachmentModal && selectedAttachmentId && (
        <Suspense>
          <DeleteAttachmentModal
            id={selectedAttachmentId}
            onClose={() => {
              setSelectedAttachmentId(undefined)
              setShowDeleteAttachmentModal(false)
            }}
            onSuccess={() => {
              window.renderAccountReactRoot?.()
            }}
          />
        </Suspense>
      )}
      <div className="flex flex-col gap-6">
        <div>
          {attachments.map(
            (attachment) =>
              attachment && (
                <div key={attachment.id} className="flex gap-3 !p-3">
                  <TaskFileAttachment attachment={attachment} />
                  <div className="flex w-full flex-col items-start justify-center gap-[0.375rem]">
                    <span className="text-sm font-medium leading-[1.125rem] text-gonk">
                      {getFileName(attachment.name, attachment.url)}
                    </span>
                    {attachment.size && (
                      <span className="text-xs font-normal leading-[1.125rem] text-gonk">
                        {sizeToReadableString(attachment.size)}
                      </span>
                    )}
                  </div>
                  <button
                    data-attachment-id={attachment?.id}
                    className="delete-task-attachment flex h-12 w-12 min-w-[3rem] items-center justify-center"
                    onClick={() => {
                      setSelectedAttachmentId(attachment.id)
                      setShowDeleteAttachmentModal(true)
                    }}
                  >
                    <span className="sr-only">
                      Delete attachment {attachment.id}
                    </span>
                    <XIcon className="h-4 w-4" />
                  </button>
                </div>
              )
          )}
        </div>
      </div>
    </>
  )
}

export default TaskFiles

import { init, BrowserTracing } from '@sentry/browser'
import 'formvalidation-v1.7.0/dist/js/FormValidation.full.customized'
import 'formvalidation-v1.7.0/dist/js/plugins/Bootstrap.customized'
import SignaturePad from 'signature_pad'
import { setupAxios } from './axiosConfig'
import _ from 'lodash'
import $ from 'jquery'
import axios from 'axios'
import './progressBar'
import './preloader'
import 'bootstrap-select'
import 'jquery-mask-plugin'
import '@shopify/draggable'

/**
 * configures axios to always set the `X-Requested-With: XMLHttpRequest` and
 * `Authorization: Bearer ${window.ENV.CURRENT_USER_JWT} headers.
 * And adds an interceptor so graphql errors can be handled with a try/catch
 */
setupAxios()

if (window.ENV?.SENTRY_DSN) {
  init({
    environment: window.ENV.APP_ENV || 'local',
    release: `solflow@${window.ENV.RELEASE_SHA}`,
    dsn: window.ENV.SENTRY_DSN,
    integrations: [new BrowserTracing()],
    ignoreErrors: [/Request failed with status code 40[01]/],
    sampleRate: 0.25, // errors
    tracesSampleRate: 0.02, // transactions
  })
}

function exposeToDevConsole(
  name: string,
  library: any,
  msg: (name: string) => string
) {
  // @ts-expect-error
  window[name] = new Proxy(library, {
    get(target, prop, receiver) {
      console.warn(msg(name))
      return Reflect.get(target, prop, receiver)
    },
  })
}

for (const [key, lib] of Object.entries({ axios, $, _ })) {
  exposeToDevConsole(
    key,
    lib,
    (name) =>
      `\`window.${name}\` is just here to provide easier debugging in the browser dev console, you shouldn't rely on it in your app code. Explicitly import it into your code instead.`
  )
}

window.formValidations = {}

window.SignaturePad = SignaturePad

/**
 * Enable Bootstrap tooltips
 */
$(() => {
  $('[data-toggle="tooltip"]').tooltip()
})

// Monkey patch jQuery's append so that we can wrap modal backdrops inside
// our .bootstrap class.
const originalAppend = $.fn.append
$.fn.append = function (...args: any) {
  if ($(args[0])?.hasClass('modal-backdrop')) {
    const container = $('<div class="bootstrap"></div>').appendTo($('body'))
    return originalAppend.apply(container, args)
  }
  return originalAppend.apply(this, args)
}

// Monkey patch jQuery's remove so that we can remove our .bootstrap class
// wrapper as well when a modal backdrop is removed.
const originalRemove = $.fn.remove
$.fn.remove = function (...args) {
  const $context = $(this)
  if ($context?.hasClass('modal-backdrop')) {
    return originalRemove.apply($context.parent(), args)
  }
  return originalRemove.apply(this, args)
}

import Button from './Button'
import React from 'react'

export default function IconButton<T extends React.ElementType = 'button'>({
  icon,
  as,
  description,
  children,
  ...props
}: Omit<Parameters<typeof Button<T>>[0], 'as'> & {
  icon: React.ElementType
  description: NonNullable<React.ReactNode>
  as?: 'button' | 'a'
}) {
  return (
    <Button {...props} as={as} leadingIcon={icon} iconOnly>
      <span className="sr-only">{description}</span>
    </Button>
  )
}

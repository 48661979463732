export default function CompensationIcons({
  className,
}: {
  className?: string
}) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M9 15.9H3.9C2.85 15.9 2 15.05 2 14V4.9C2 3.85 2.85 3 3.9 3H19.1C20.15 3 21 3.85 21 4.9V10M6 13H5M18 6H17M15.33 15.0179C13.478 15.1079 12 16.6259 12 18.4999C12 20.4329 13.567 21.9999 15.5 21.9999C16.897 21.9999 18.093 21.1759 18.655 19.9919M18.5 20C16.567 20 15 18.433 15 16.5C15 14.567 16.567 13 18.5 13C20.434 13 22 14.567 22 16.5C22 18.433 20.434 20 18.5 20ZM13.179 7.97102C14.106 8.89802 14.106 10.402 13.179 11.33C12.252 12.257 10.748 12.257 9.82001 11.33C8.89301 10.403 8.89301 8.89902 9.82001 7.97102C10.748 7.04302 12.252 7.04302 13.179 7.97102Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

import { SearchAccountComponent } from '.'
import Modal from '../Modal'

export function SearchAccountModal({
  open,
  onClose,
}: {
  open: boolean
  onClose: () => void
}) {
  return (
    <Modal
      modalOpen={open}
      body={<SearchAccountComponent />}
      paddingClasses=""
      className="mt-[20vh] h-fit"
      positionClasses="justify-center"
      onClose={onClose}
      hideCancelButton
      hideCloseButton
      hideConfirmButton
    />
  )
}

import classNames from 'classnames'
import NotificationDrawer from './NotificationDrawer'
import { useEffect, useRef } from 'react'
import Button from '../../Button'
import { ChevronLeftIcon } from '@heroicons/react/outline'

type DesktopNotificationDrawerWrapperProps = {
  isOpen?: boolean
  setIsOpen: (isOpen: boolean) => void
  isExpanded?: boolean
}

const DesktopNotificationDrawerWrapper: React.FC<
  DesktopNotificationDrawerWrapperProps
> = ({ isOpen, setIsOpen, isExpanded }) => {
  const drawerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        isOpen &&
        drawerRef.current &&
        !drawerRef.current.contains(event.target as Node) &&
        (event.target as Element).parentElement?.getAttribute('data-testid') !==
          'notifications-button'
      ) {
        setIsOpen(false)
      }
    }

    document.addEventListener('click', handleClickOutside)

    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [isOpen, setIsOpen])
  return (
    <div
      ref={drawerRef}
      data-testid="notification-drawer"
      aria-expanded={isOpen}
      className={classNames(
        isExpanded ? 'left-72' : 'left-[5.5rem]',
        'absolute z-50 h-full w-[396px] flex-col justify-between',
        'border-atst bg-snowtrooper duration-200 ease-in',
        isOpen ? 'translate-x-0 border-r-2' : '-translate-x-[396px]'
      )}
    >
      <div className="flex items-center justify-start border-b-2 py-2">
        <Button className="px-1 mx-2" iconOnly variant="bareMinimal">
          <ChevronLeftIcon
            className="h-8 w-8"
            onClick={() => setIsOpen(false)}
          />
        </Button>
        <p className="text-2xl font-semibold">Notifications</p>
      </div>
      <NotificationDrawer />
    </div>
  )
}

export default DesktopNotificationDrawerWrapper

import classNames from 'classnames'
import { useEffect, useRef, useState } from 'react'
import ReactTooltip from 'react-tooltip'
import { displayName } from '../../../helpers/view-helpers'
import currentUser from '../../utils/currentUser'
import Avatar from '../Avatar'
import NavLink from './NavLink'
import ProfileLinks from './ProfileLinks'
import { MenuIcon } from '@heroicons/react/outline'
import BottomHalfMenu from './BottomHalfMenu'
import { useNotifications } from '@novu/notification-center'

type BottomHalfProps = {
  expanded?: boolean
  isNotificationDrawerOpen?: boolean
  setIsNotificationDrawerOpen?: (isOpen: boolean) => void
  isShortScreenMenuOpen?: boolean
  setIsShortScreenMenuOpen?: (isOpen: boolean) => void
}
export default function BottomHalf({
  expanded,
  isNotificationDrawerOpen,
  setIsNotificationDrawerOpen,
  isShortScreenMenuOpen,
  setIsShortScreenMenuOpen,
}: BottomHalfProps) {
  const [openPopup, setOpenPopup] = useState(false)
  const ref = useRef<HTMLDivElement>(null)
  const user = currentUser()
  const notifications = useNotifications()

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setOpenPopup(false)
      }
    }

    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  }, [])

  return (
    <div className="h-fit">
      <div
        className="flex h-full flex-col justify-between gap-1.5 border-t-2 border-atst pt-2 pb-4 pl-[0.125rem]"
        data-testid="half-bottom-side-navigation"
      >
        {!expanded && <ReactTooltip type="light" />}
        <div className="short:hidden block">
          <BottomHalfMenu
            expanded={expanded}
            isNotificationDrawerOpen={isNotificationDrawerOpen}
            setIsNotificationDrawerOpen={setIsNotificationDrawerOpen}
          />
        </div>
        <div className="short:block hidden">
          <NavLink
            onClick={() => setIsShortScreenMenuOpen?.(!isShortScreenMenuOpen)}
            as="button"
            iconElement={
              <div className="relative" data-testid="notifications-button">
                <MenuIcon className="h-12 min-h-[3rem] w-12 min-w-[3rem] stroke-vader p-2.5" />
                {notifications?.unseenCount > 0 && (
                  <div
                    data-testid="notification-badge"
                    className={classNames(
                      'absolute bottom-2.5 right-2.5 h-3 w-3 rounded-full border-2 border-snowtrooper bg-error'
                    )}
                  ></div>
                )}
              </div>
            }
            className="h-[3.75rem] min-w-[3.75rem] items-center pl-[0.375rem]"
            expanded={expanded}
            data-testid="short-screen-menu-button"
            data-tip="Menu"
            data-place="right"
            data-effect="solid"
            data-class="bg-snowtrooper p-2 rounded-md opacity-100 shadow-sm"
          >
            Menu
          </NavLink>
        </div>
        <div ref={ref}>
          <NavLink
            as="button"
            iconElement={<Avatar size="12" user={user} />}
            expanded={expanded}
            data-testid="user-button"
            className="h-[3.75rem] min-w-[3.75rem] items-center pl-[0.375rem] hover:!bg-snowtrooper"
            onClick={() => setOpenPopup((prevState) => !prevState)}
            data-tip="Profile"
            data-place="right"
            data-effect="solid"
            data-class="bg-snowtrooper p-2 rounded-md opacity-100 shadow-sm"
          >
            <div className="p1 my-auto flex w-40 flex-col items-start text-left text-sm">
              <span className="whitespace-nowrap">{displayName(user)}</span>
              <span className="whitespace-nowrap text-xs text-deathstar">
                {user.email}
              </span>
            </div>
          </NavLink>
          {openPopup && (
            <div
              className={classNames(
                'absolute bottom-2 z-70 flex h-56 w-64 flex-col gap-2 rounded-xl bg-snowtrooper p-3 shadow-md transition-all duration-200 ease-in',
                expanded ? 'left-56' : 'left-24'
              )}
              data-testid="popup-profile"
            >
              <ProfileLinks />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

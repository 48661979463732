export default function AccountsIcon({ className }: { className?: string }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M7.25605 10.8382C7.40296 9.85922 8.24396 9.13501 9.2339 9.13501H19.5309C20.5173 9.13501 21.3564 9.85415 21.5074 10.8289L22.5354 17.4665C22.7231 18.6786 21.7855 19.7726 20.5589 19.7726H8.23781C7.01502 19.7726 6.07849 18.685 6.25996 17.4758L7.25605 10.8382Z"
        stroke="#3D3D3D"
        strokeWidth="1.5"
      />
      <path
        d="M1.52638 8.67935H1.03617"
        stroke="#3D3D3D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.15591 3.594V3.1038"
        stroke="#3D3D3D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.71016 12.8633L2.36301 13.2105"
        stroke="#3D3D3D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.0979 4.95534L11.445 4.60819"
        stroke="#3D3D3D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.0052 5.02919L2.65806 4.68204"
        stroke="#3D3D3D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.5132 9.17915C10.5132 7.32482 9.00998 5.82159 7.15565 5.82159C5.30132 5.82159 3.7981 7.32482 3.7981 9.17915C3.7981 10.9511 5.17069 12.4024 6.91062 12.5279"
        stroke="#3D3D3D"
        strokeWidth="1.5"
      />
      <path
        d="M14.7167 19.8366C14.714 19.4224 14.3761 19.0888 13.9619 19.0915C13.5477 19.0942 13.2141 19.4321 13.2168 19.8463L14.7167 19.8366ZM13.2285 21.6653C13.2312 22.0795 13.5691 22.4131 13.9833 22.4104C14.3975 22.4078 14.7311 22.0698 14.7285 21.6556L13.2285 21.6653ZM13.2168 19.8463L13.2285 21.6653L14.7285 21.6556L14.7167 19.8366L13.2168 19.8463Z"
        fill="#3D3D3D"
      />
    </svg>
  )
}

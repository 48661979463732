import { startCase } from 'lodash'
import type { DisplayNameFieldsFragment, User_Role } from '../generated/graphql'
import { UserStatus, Organization_Type } from '../generated/graphql'
import { holidays } from './holidays'
import { DateTime } from 'luxon'

export type DisplayNameFields = Omit<
  DisplayNameFieldsFragment,
  '__typename' | 'id'
>

/************
 * Use these `display<Thing>` helpers whenever you need to show that type of thing
 * in the UI. That way we can make sure we are consistent whenever we display
 * that type of thing, and we have one place to change the logic if we need to.
 ***********/

/**
 * Whenever you display a user's name, you should pass it through this helper
 * so it can handle preferred name, middle name, etc. Make sure your GraphQL
 * query always asks for _all_ the fields that this helper needs. To do
 * that you should use the GraphQL fragment `...DisplayNameFields`.
 */
export function displayName({
  preferred_name,
  first_name,
  middle_name,
  last_name,
}: DisplayNameFields): string {
  return (
    preferred_name ||
    [first_name, middle_name, last_name].filter(Boolean).join(' ')
  )
}

export function displayRole(role: User_Role) {
  return startCase(role)
}

export function displayStatus(status: UserStatus) {
  switch (status) {
    case UserStatus.Suspended:
      return 'Suspended'
    case UserStatus.Inactive:
      return 'Deactivated'
    default:
      return startCase(status)
  }
}

export function displayOrgType(orgType: Organization_Type) {
  if (
    orgType === Organization_Type.Company &&
    !window.FEATURE_FLAGS.dealer_integration
  ) {
    return Organization_Type.Territory
  }
  return startCase(orgType)
}

export function orgsThatCanHold(orgType: Organization_Type) {
  if (!window.FEATURE_FLAGS.dealer_integration) {
    switch (orgType) {
      case Organization_Type.Company:
        return [Organization_Type.Admin]
      case Organization_Type.Region:
        return [Organization_Type.Company]
      case Organization_Type.Division:
        return [Organization_Type.Region]
      case Organization_Type.Team:
        return [Organization_Type.Region, Organization_Type.Division]
    }
  }

  switch (orgType) {
    case Organization_Type.Company:
      return [Organization_Type.Admin]
    case Organization_Type.Territory:
      return [Organization_Type.Company]
    case Organization_Type.Division:
      return [Organization_Type.Territory]
    case Organization_Type.Region:
      return [Organization_Type.Division]
    case Organization_Type.Team:
      return [
        Organization_Type.Region,
        Organization_Type.Division,
        Organization_Type.Territory,
        Organization_Type.Company,
      ]
  }

  return []
}

const statusColors = {
  active: 'bg-greedo-300',
  inactive: 'bg-error',
  suspended: 'bg-error',
  onboarding: 'bg-success',
} as const

export function statusColor(status: UserStatus) {
  return statusColors[status]
}

/**
 * This is what we use to determine which color the user's avatar initials
 * should be, based on the random background color we picked.
 * Will be white when the background color is dark, and black when it's light.
 */
export function getTextColorFromBgColor(bgColor: string) {
  const [r, g, b] = bgColor
    .replace(
      /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
      (_: any, r: string, g: string, b: string) => {
        return `${r + r}${g + g}${b + b}`
      }
    )
    .substring(1)
    .match(/.{2}/g)
    ?.map((x: string) => parseInt(x, 16)) || [255, 255, 255]
  const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b
  return luminance > 128 ? 'text-black' : 'text-white'
}

/**
 * Used to generate a random background color based on a string. Specifically,
 * for our Avatar component, we use this to generate a random color based on
 * the user's name.
 */
export function stringToColor(string: string) {
  let hash = 0
  for (let i = 0; i < string.length; i++) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash)
  }

  let color = '#'
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff
    color += `00${value.toString(16)}`.slice(-2)
  }

  return color
}
export function disableSundays(d: Date) {
  return d.getDay() === 0
}

export function disablePastDays(d: Date) {
  const today = new Date()
  today.setHours(0)
  today.setMinutes(0)
  today.setSeconds(0)
  today.setMilliseconds(0)
  return d < today
}

export function disableDatesAfterTwoWeeks(date: Date) {
  const today = new Date()
  today.setHours(0, 0, 0, 0)
  const twoWeeksLater = new Date(today)
  twoWeeksLater.setDate(twoWeeksLater.getDate() + 14)
  return date > twoWeeksLater
}

export function disableHolidays(date: Date) {
  const localDate = DateTime.fromJSDate(date).startOf('day').setZone('local')
  return holidays.some((holiday) => holiday.equals(localDate))
}

export function formatTableDate(timestamp: number) {
  return DateTime.fromMillis(timestamp).toFormat('MM/dd/yyyy HH:mm a')
}

import NotificationList from './NotificationList'

const NotificationDrawer = () => {
  return (
    <div className="h-full">
      <NotificationList />
    </div>
  )
}

export default NotificationDrawer

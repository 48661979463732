import classNames from 'classnames'
import type { Ref, ComponentPropsWithoutRef } from 'react'
import Spinner from './Spinner'

export const SIZE_MAP = {
  tiny: {
    button: 'px-0 py-0 leading-4 text-sm max-h-4',
    buttonIconOnly: 'px-0 py-0 leading-4 text-sm',
    leadingIcon: '-ml-0.5 mr-2 h-4 w-4',
    leadingIconOnly: 'h-4 w-4',
    trailingIcon: 'ml-2 -mr-0.5 h-4 w-4',
  },
  small: {
    button: 'px-3 py-2 leading-4 text-sm max-h-10',
    buttonIconOnly: 'p-1 leading-4 text-sm',
    leadingIcon: '-ml-0.5 mr-2 h-4 w-4',
    leadingIconOnly: 'h-6 w-6',
    trailingIcon: 'ml-2 -mr-0.5 h-4 w-4',
  },
  medium: {
    button: 'px-3 py-2 text-sm',
    buttonIconOnly: 'p-2 text-sm',
    leadingIcon: '-ml-1 mr-2 h-5 w-5',
    leadingIconOnly: '-ml-1.5 -mr-1.5 h-6 w-6',
    trailingIcon: 'ml-2 -mr-1 h-5 w-5',
  },
  large: {
    button: 'px-6 py-2.5 text-base',
    buttonIconOnly: 'p-2.5 text-base',
    leadingIcon: '-ml-1 mr-3 h-5 w-5',
    leadingIconOnly: '-ml-0.5 -mr-0.5 h-5 w-5',
    trailingIcon: 'ml-3 -mr-1 h-5 w-5',
  },
  xl: {
    button: 'px-8 py-3 text-base',
    buttonIconOnly: 'px-6 py-3 text-base',
    leadingIcon: '-ml-1 mr-3 h-5 w-5',
    leadingIconOnly: '-ml-1 -mr-1 h-5 w-5',
    trailingIcon: 'ml-3 -mr-1 h-5 w-5',
  },
  min: {
    button: 'px-6 py-2.5 text-base w-32',
    buttonIconOnly: 'px-3 py-2.5 text-base',
    leadingIcon: '-ml-1 mr-3 h-5 w-5',
    leadingIconOnly: '-ml-0.5 -mr-0.5 h-5 w-5',
    trailingIcon: 'ml-3 -mr-1 h-5 w-5',
  },
  mediumRound: {
    button: 'px-2.5 py-2.5 text-sm',
    buttonIconOnly: 'px-2.5 py-2.5 text-sm',
    leadingIcon: '-ml-2 mr-2 h-5 w-5',
    leadingIconOnly: '-ml-1.5 -mr-1.5 h-6 w-6',
    trailingIcon: 'ml-2 -mr-2 h-5 w-5',
  },
} as const

export const COLOR_MAP = {
  primary: {
    solid: 'bg-gonk hover:bg-gray-600',
    outline: 'text-gonk hover:!bg-gray-200',
    border: 'border-gonk',
    focus: '!outline-gonk',
  },
  secondary: {
    solid: 'bg-link-500 hover:bg-link-700',
    outline: 'text-link-500 hover:!bg-link-200',
    border: 'border-link-500',
    focus: '!outline-link-500',
  },
  complete: {
    solid: 'bg-complete-500 hover:bg-complete-300',
    outline: 'text-complete-500 hover:bg-emerald-100',
    border: 'border-complete-500',
    focus: '!outline-complete-500',
  },
  error: {
    solid: 'bg-error hover:bg-red-400',
    outline: 'text-error hover:!bg-red-200',
    border: 'border-error',
    focus: '!outline-error',
  },
  success: {
    solid: 'bg-success hover:bg-jabba',
    outline: 'text-success hover:!bg-success',
    border: 'border-success',
    focus: '!outline-success',
  },
  link: {
    solid: 'bg-link-500 hover:bg-link-700',
    outline: 'text-link-500 hover:!bg-link-200',
    border: 'border-link-500',
    focus: '!outline-link-500',
  },
  unclaimed: {
    solid: 'bg-blue-300 hover:bg-blue-700',
    outline: 'text-link-500 hover:!bg-link-200',
    border: 'border-link-500',
    focus: '!outline-link-500',
  },
  claimed: {
    solid: 'bg-green-500 hover:bg-orange-500',
    outline: 'text-link-500 hover:!bg-link-200',
    border: 'border-link-500',
    focus: '!outline-link-500',
  },
  disabledClaimed: {
    solid: 'bg-green-500',
    outline: 'text-link-500 hover:!bg-link-200',
    border: 'border-link-500',
    focus: '!outline-link-500',
  },
  perform: {
    solid: 'bg-stratos-blue-300 hover:bg-stratos-blue-500',
    outline: 'text-stratos-blue-500 hover:!bg-stratos-blue-300',
    border: 'border-stratos-blue-300',
    focus: '!outline-stratos-blue-500',
  },
  hoth: {
    solid: 'bg-hoth hover:bg-atst !text-deathstar !border-1 !border-atat',
    outline: 'text-deathstar hover:!bg-atat',
    border: 'border-atat',
    focus: '!outline-atat',
  },
} as const

export type StyleVariants = keyof (typeof COLOR_MAP)[keyof typeof COLOR_MAP]
export type ColorClasses = Record<StyleVariants, string>

export const VARIANT_MAP = {
  solid: (
    colors: ColorClasses
  ) => `text-snowtrooper !border-transparent ${colors?.solid}
                      disabled:bg-atst disabled:!text-mando ${colors?.focus}`,
  outline: (
    colors: ColorClasses
  ) => `bg-snowtrooper ${colors.outline} ${colors.border} ${colors?.focus}
        disabled:!bg-atst disabled:!text-mando disabled:!border-atat`,
  bare: (colors: ColorClasses) =>
    `bg-transparent border-transparent hover:bg-atst/60 ${colors.outline} ${colors?.focus}
     disabled:!bg-atst disabled:!text-mando`,

  bareMinimal: (colors: ColorClasses) =>
    `bg-transparent border-transparent ${colors.outline} ${colors?.focus}
  disabled:!text-mando`,
} as const

export const ROUNDNESS_MAP = {
  sharp: 'rounded-sm',
  semiround: 'rounded-md',
  round: '!rounded-lg',
  pill: 'rounded-full',
} as const

export type ButtonOwnProps<T extends React.ElementType> = {
  as?: T
  color?: string | ColorClasses
  iconOnly?: boolean
  leadingIcon?: React.ElementType
  leadingIconClassname?: string
  loading?: boolean
  size?: keyof typeof SIZE_MAP
  variant?: keyof typeof VARIANT_MAP
  hideRing?: boolean
  rounded?: keyof typeof ROUNDNESS_MAP
  trailingIcon?: React.ElementType
  buttonRef?: Ref<HTMLButtonElement> | null
}

export type ButtonProps<T extends React.ElementType> = ButtonOwnProps<T> &
  Omit<ComponentPropsWithoutRef<T>, keyof ButtonOwnProps<T>>

export default function Button<T extends React.ElementType = 'button'>({
  as,
  id,
  children,
  leadingIcon: LeadingIcon,
  trailingIcon: TrailingIcon,
  leadingIconClassname,
  className,
  buttonRef,
  color = 'primary',
  iconOnly = false,
  loading = false,
  rounded = 'round',
  size = 'large',
  variant = 'solid',
  hideRing = false,
  ...props
}: ButtonProps<T>) {
  const colors =
    typeof color === 'string'
      ? COLOR_MAP[color as keyof typeof COLOR_MAP]
      : color
  const colorClasses = VARIANT_MAP[variant](colors)
  const sizeClasses = SIZE_MAP[size]

  const Component = as || 'button'
  return (
    <Component
      id={id}
      ref={buttonRef}
      {...(Component === 'button' ? { type: 'button' } : {})}
      className={classNames(
        'inline-flex items-center justify-center !border-2 font-medium',
        hideRing
          ? 'focus-visible:outline-none'
          : '!outline-2 outline-offset-2 focus-visible:!outline',
        'disabled:cursor-default',
        ROUNDNESS_MAP[rounded],
        colorClasses,
        sizeClasses[iconOnly ? 'buttonIconOnly' : 'button'],
        className
      )}
      {...props}
    >
      {loading && (
        <Spinner
          data-testid={id && `${id}-loading-spinner`}
          size="tiny"
          className="mr-2"
        />
      )}
      {LeadingIcon && (
        <LeadingIcon
          className={classNames(
            sizeClasses[iconOnly ? 'leadingIconOnly' : 'leadingIcon'],
            leadingIconClassname
          )}
          aria-hidden="true"
          data-testid={id && `${id}-leadingIcon`}
        />
      )}
      <span className="flex shrink-0">{children}</span>
      {TrailingIcon && (
        <TrailingIcon
          className={sizeClasses.trailingIcon}
          aria-hidden="true"
          data-testid={id && `${id}-trailingIcon`}
        />
      )}
    </Component>
  )
}

import classNames from 'classnames'

export default function SkeletonLoader() {
  return (
    <>
      {[1, 2, 3, 4, 5].map((i) => (
        <div
          key={i}
          className={classNames(
            'w-full border-t-1 border-atst p-4',
            i === 0 ? 'border-t-0 sm:border-t-1' : null
          )}
        >
          <svg
            width="300"
            height="48"
            viewBox="0 0 300 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g style={{ mixBlendMode: 'multiply' }}>
              <rect width="120" height="24" fill="#F5F5F5" />
            </g>
            <g style={{ mixBlendMode: 'multiply' }}>
              <rect y="30" width="300" height="18" fill="#F5F5F5" />
            </g>
          </svg>
        </div>
      ))}
    </>
  )
}

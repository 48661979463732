export default function CompassMapIcon({ className }: { className?: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.67804 9.70203L16.0017 7.99832L14.322 14.2979L7.99834 16.0017L9.67804 9.70203Z"
        stroke="#323232"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.0025 21.0037H5.9975C4.33981 21.0037 2.99625 19.6602 2.99625 18.0025V5.9975C2.99625 4.33981 4.33981 2.99625 5.9975 2.99625H18.0025C19.6602 2.99625 21.0037 4.33981 21.0037 5.9975V18.0025C21.0037 19.6602 19.6602 21.0037 18.0025 21.0037Z"
        stroke="#323232"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.321 14.301L9.67903 9.69904"
        stroke="#323232"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

import {
  lazy,
  StrictMode,
  Suspense,
  useContext,
  useEffect,
  useState,
} from 'react'
import { createRoot } from 'react-dom/client'
import type { RouteObject } from 'react-router-dom'
import {
  createBrowserRouter,
  isRouteErrorResponse,
  Outlet,
  redirect,
  RouterProvider,
  ScrollRestoration,
  useRouteError,
} from 'react-router-dom'
import { Provider } from 'urql'

import NavContext, {
  NavContextProvider,
} from './components/SideNavigation/NavContext'
import SideNavigation from './components/SideNavigation'
import MobileNavigation from './components/SideNavigation/MobileNavigation'
import urqlClient from './utils/graphql-client'
import { entries } from '../helpers/ts-utils'
import Portal from './components/Portal'
import Ufo from './components/Ufo'
import { hidePreloader } from '../preloader'
import currentUser, {
  canViewAccountMap,
  canViewAdminPage,
  canViewCompetitions,
  canViewCrew,
  canViewMedia,
  canViewOpsPage,
} from './utils/currentUser'
import { User_Role } from '../generated/graphql'
import AnnouncementAgreementModal from './views/Announcements/AnnouncementAgreementModal'

function HidePreloaderAfterRender() {
  useEffect(() => {
    // it will keep the logo loader active til we finish manually in these routes
    // the porpouse is to keep the loader active until the graphql query finish
    const notHidePreloaderRoutes = ['/queues/design-queue']

    const currentPath = window.location.pathname
    if (!notHidePreloaderRoutes.includes(currentPath)) {
      hidePreloader()
    }
  }, [])
  return null
}

function lazyLoad(component: () => Promise<any>) {
  const Component = lazy(component)
  return (
    <Suspense fallback={<div>loading...</div>}>
      <Component />
      <HidePreloaderAfterRender />
    </Suspense>
  )
}

const routes: RouteObject[] = entries({
  'account/:id': () => import('./views/Account'),
  accounts: () => import('./views/Accounts'),
  tasks: () => import('./views/Tasks'),
  dashboard: () => import('./views/Dashboard'),
  announcements: () => import('./views/Announcements'),
  teams: () => import('./views/Teams'),
  orgs: () => import('./views/TeamsV2'),
  userList: () => import('./views/UserList'),
  calendar: () => import('./views/Calendar'),
  'calendar/appointment-controls': () => import('./views/AppointmentControls'),
  pipeline: () => import('./views/Pipeline'),
  leaderboard: () => import('./views/Leaderboard'),
  ...(window.FEATURE_FLAGS.tableau_compensation
    ? { compensation: () => import('./views/Compensation') }
    : {}),
  ...(window.FEATURE_FLAGS.tableau_top_to_the_race
    ? { 'race-to-the-top': () => import('./views/RaceToTheTop') }
    : {}),
  ...(canViewAccountMap() ? { map: () => import('./views/AccountMap') } : {}),
  ...(canViewCrew()
    ? {
        onboarding: () => import('./views/Onboarding'),
        'onboarding/:userId': () => import('./views/Onboarding'),
        recruit: () => import('./views/Recruit'),
      }
    : {}),
  'kings-pardon': () => import('./views/KingsPardon'),
  'queues/design-queue': () => import('./views/DesignQueue'),
  'queues/setter-queue': () => import('./views/SetterQueue'),
  'users/:userId': () => import('./views/users/Profile'),
  'users/:userId/edit': () => import('./views/users/Edit'),
  stats: () => import('./views/Leaderboard'),
  'daily-brief': () => import('./views/Stratos/DailyBrief'),
  login: () => import('./views/Login/index'),
  '/queues/closer-appointment-queue': () => import('./views/CloserQueue'),
  '/queues/proposal-queue': () => import('./views/ProposalQueue'),
  '404': () => import('./views/404'),
  queues: () => import('./views/Queues'),
  '/no-user-account': () => import('./views/NoUserAccount'),
  '/join-lumio': () => import('./views/CreateLead'),
} as Record<string, () => Promise<any>>).map(([path, component]) => ({
  path,
  element: lazyLoad(component),
}))
if (canViewMedia()) {
  routes.push(
    {
      path: '/courses',
      element: lazyLoad(() => import('./views/Courses')),
    },
    {
      path: '/courses/:courseId',
      element: lazyLoad(() => import('./views/Courses/Course')),
    },
    {
      path: '/courses/:courseId/sections/:sectionId',
      element: lazyLoad(() => import('./views/Courses/Section')),
    },
    {
      path: 'library',
      element: lazyLoad(() => import('./views/Library')),
    }
  )
}

if (canViewCompetitions()) {
  routes.push({
    path: 'competitions',
    errorElement: <RootBoundary />,

    children: [
      {
        // Once we have a competitions index page, put that here and remove this redirect.
        index: true,
        loader() {
          throw redirect('/competitions/the-crown')
        },
      },
      {
        path: ':competitionSlug',
        loader: async (loaderFunctionArgs) => {
          return (
            await import('./views/Competitions/$competitionSlug/index')
          ).loader(loaderFunctionArgs)
        },
        element: lazyLoad(
          () => import('./views/Competitions/$competitionSlug/index')
        ),
      },
    ],
  })

  if (canViewAdminPage()) {
    routes.push({
      path: 'admin',
      errorElement: <RootBoundary />,

      children: [
        {
          // Once we have a admin index page, put that here and remove this redirect.
          index: true,
          loader() {
            throw redirect('/admin/utility-companies')
          },
        },
        {
          path: 'utility-companies',
          element: lazyLoad(() => import('./views/Admin/UtilityCompanies')),
        },
        {
          path: 'selling-states',
          element: lazyLoad(() => import('./views/Admin/SellingStates')),
        },
        {
          path: 'selling-states/:state',
          element: lazyLoad(
            () => import('./views/Admin/SellingStates/SellingState')
          ),
        },
        window.FEATURE_FLAGS.multiple_proposals_core
          ? {
              path: 'proposal-platforms',
              element: lazyLoad(
                () => import('./views/Admin/ProposalPlatforms')
              ),
            }
          : {},
        window.FEATURE_FLAGS.multiple_proposals_core
          ? {
              path: 'proposal-platforms/:platform',
              element: lazyLoad(
                () => import('./views/Admin/ProposalPlatforms/ProposalPlatform')
              ),
            }
          : {},
      ],
    })
  }
  if (canViewOpsPage() && window.FEATURE_FLAGS['form_filler']) {
    routes.push({
      path: '/ops',
      errorElement: <RootBoundary />,
      children: [
        {
          path: 'forms',
          element: lazyLoad(() => import('./views/Ops/Forms')),
        },
        {
          path: 'templates',
          element: lazyLoad(() => import('./views/Ops/Templates')),
        },
      ],
    })
  }
}

if (currentUser().role !== User_Role.SalesRep) {
  routes.push({
    path: '/queues/manager-appointment-queue',
    element: lazyLoad(() => import('./views/ManagerQueue')),
  })
}

// TODO: someday come up with some prettier react router error rendering
// but this is at least a step in the right direction
export function RootBoundary() {
  const error = useRouteError()

  console.error('caught react-router error', error)

  const message =
    (isRouteErrorResponse(error) &&
      (error.data ||
        {
          404: 'This page does not exist',
          401: 'You are not authorized to see this',
          503: 'Looks like our API is down',
        }[error.status])) ||
    'Something went wrong'

  return (
    <>
      <div className="mt-5 flex flex-col items-center gap-5">
        {isRouteErrorResponse(error) && error.status === 404 && (
          <div className="rounded-full border-8 border-white bg-white">
            <Ufo />
          </div>
        )}
        <span className="font-semibold text-black">{message}</span>
      </div>

      <HidePreloaderAfterRender />
    </>
  )
}

const GlobalAddNewModal = lazy(() => import('./components/GlobalAddNewModal'))
const LeadCreationModal = lazy(() => import('./components/LeadCreationModal'))
const NewUserModal = lazy(() => import('./components/NewUserModal'))
const CreateOrganizationFormModal = lazy(
  () => import('./components/CreateOrganizationModal')
)

export function AddNewModals() {
  const { addNewOpen, setAddNewOpen, leadCreationOpen, setLeadCreationOpen } =
    useContext(NavContext)

  const [newUserModalOpen, setNewUserModalOpen] = useState(false)
  const [newOrgModalOpen, setNewOrgModalOpen] = useState(false)

  return (
    <>
      <>
        {addNewOpen && (
          <Suspense>
            <GlobalAddNewModal
              onClose={() => setAddNewOpen(false)}
              onNewUserClicked={() => setNewUserModalOpen(true)}
              onNewOrgClicked={() => setNewOrgModalOpen(true)}
              onNewLeadClicked={() => setLeadCreationOpen(true)}
            />
          </Suspense>
        )}
      </>
      <>
        {leadCreationOpen && (
          <Suspense>
            <LeadCreationModal onClose={() => setLeadCreationOpen(false)} />
          </Suspense>
        )}
      </>
      <>
        {newUserModalOpen && (
          <Suspense>
            <NewUserModal onClose={() => setNewUserModalOpen(false)} />
          </Suspense>
        )}
      </>
      <>
        {newOrgModalOpen && (
          <Suspense>
            <CreateOrganizationFormModal
              showModal
              onClose={() => setNewOrgModalOpen(false)}
            />
          </Suspense>
        )}
      </>
    </>
  )
}

export function renderReactApp() {
  const router = createBrowserRouter([
    {
      errorElement: <RootBoundary />,
      element: (
        <>
          <Portal container={document.getElementById('react-sidebar')}>
            <SideNavigation />
          </Portal>
          <Portal container={document.getElementById('react-sidebar-mobile')}>
            <MobileNavigation />
          </Portal>
          <Portal container={document.getElementById('react-app-container')}>
            <Outlet />
          </Portal>
          <AddNewModals />
          <ScrollRestoration />
        </>
      ),
      children: [
        ...routes,
        {
          path: '/*',
          element: lazyLoad(() => import('./views/404')),
        },
      ],
    },
  ])

  // We need an element to render into, even though all our actual rendering
  // will be into the react-<whatever> Portals (so that we don't blow any
  // existing dom nodes away if we get to a pre react route).
  const dummy = document.createElement('div')
  document.body.appendChild(dummy)
  const root = createRoot(dummy)
  root.render(
    <StrictMode>
      <Provider value={urqlClient}>
        <AnnouncementAgreementModal />
        <NavContextProvider>
          <RouterProvider router={router} />
        </NavContextProvider>
      </Provider>
    </StrictMode>
  )
}

export default function ProposalsIcon({ className }: { className?: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      className={className}
    >
      <path
        d="M18 8.5H17M14 8.5H13M9 8.5H10M6 21.5H19C20.1046 21.5 21 20.6046 21 19.5V7.5C21 6.39543 20.1046 5.5 19 5.5H9M6 21.5C7.65685 21.5 9 20.1569 9 18.5M6 21.5C4.34315 21.5 3 20.1569 3 18.5M9 5.5C9 3.84315 7.65685 2.5 6 2.5C4.34315 2.5 3 3.84315 3 5.5V18.5M9 5.5V18.5M9 18.5C9 16.8431 7.65685 15.5 6 15.5C4.34315 15.5 3 16.8431 3 18.5M5.95 18.4502C5.95011 18.4778 5.97255 18.5001 6.00013 18.5C6.02772 18.4999 6.05004 18.4775 6.05 18.4499C6.04996 18.4224 6.02759 18.4 6 18.4M17.4524 14.774V16.5C17.4524 17.0523 17.0047 17.5 16.4524 17.5H13.4524C12.9001 17.5 12.4524 17.0523 12.4524 16.5V14.726C12.4524 14.3463 12.625 13.9871 12.9215 13.7499L14.1415 12.7739C14.5981 12.4087 15.2467 12.4087 15.7032 12.7739L16.9832 13.7979C17.2798 14.0351 17.4524 14.3943 17.4524 14.774Z"
        stroke="#3D3D3D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

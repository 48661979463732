export default function TeamsIcon({ className }: { className?: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      className={className}
    >
      <ellipse
        cx="11.9999"
        cy="12.7575"
        rx="5.235"
        ry="5.235"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></ellipse>
      <circle
        cx="25.5002"
        cy="14.2425"
        r="3.75"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></circle>
      <path
        d="M3 29.9925V28.467C3 25.167 5.6745 22.4925 8.9745 22.4925H15.0255C18.3255 22.4925 21 25.167 21 28.467V29.9925"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
      <path
        d="M25.5 22.4925H27.153C30.453 22.4925 33.1275 25.167 33.1275 28.467V29.9925"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
    </svg>
  )
}

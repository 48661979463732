import { DateTime } from 'luxon'
import { useCallback, useEffect, useState } from 'react'

type TimeAgoPostsProps = {
  date: Date
}

export default function TimeAgoPosts({ date }: TimeAgoPostsProps) {
  const [timeAgo, setTimeAgo] = useState('')

  const getTimeToDisplay = useCallback(() => {
    const now = DateTime.local()
    const past = DateTime.fromJSDate(date)

    const units = [
      'years',
      'months',
      'weeks',
      'days',
      'hours',
      'minutes',
      'seconds',
    ]
    const diff = now.diff(past, units as any)

    for (const unit of units) {
      if ((diff as any)[unit] > 0) {
        const unitValue = (diff as any)[unit]
        if (unit === 'seconds') {
          return 'Just now'
        }

        return `${Math.round(unitValue)} ${
          unitValue === 1 ? unit.slice(0, -1) : unit
        } ago`
      }
    }

    return date.toLocaleDateString()
  }, [date])

  useEffect(() => {
    const updateInterval = setInterval(() => {
      setTimeAgo(getTimeToDisplay())
    }, 60 * 1000)

    setTimeAgo(getTimeToDisplay())

    return () => clearInterval(updateInterval)
  }, [getTimeToDisplay])

  return <span>{timeAgo}</span>
}

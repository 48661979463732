export default function GraduateHat({ className }: { className?: string }) {
  return (
    <svg
      fill="currentColor"
      height="36"
      viewBox="0 0 36 36"
      width="36"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="m8.98645 18.3v5.886c0 1.4235.7125 2.751 1.89605 3.5385l2.4104 1.6036c2.8515 1.896 6.5626 1.896 9.4126 0l2.4105-1.6036c1.185-.7875 1.896-2.1165 1.896-3.5385v-5.886m-12.4574-12.15739-9.26104 5.14499c-1.947 1.0815-1.947 3.8805 0 4.962l9.26104 5.145c2.142 1.1895 4.7475 1.1895 6.891 0l9.261-5.145c1.947-1.0815 1.947-3.8805 0-4.962l-9.261-5.14499c-2.1435-1.1895-4.7475-1.1895-6.891 0z"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.25"
      />
    </svg>
  )
}

import $ from 'jquery'

export function newToast(
  type: 'success' | 'complete' | 'notice' | 'error',
  message: string,
  action?: () => void,
  fitToText = false
) {
  if (type === 'success') {
    const successToast = $(`
        <div class='top-toast toast-success mb-1 rounded d-flex align-items-center justify-content-between' ${
          fitToText ? `style='width: auto !important; min-width:34vw'` : ``
        }>
          <div class='d-flex align-items-center' style='max-width: 90%;'>
            <svg data-description="check-circle from heroicons" xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
             <path stroke-linecap="round" stroke-linejoin="round" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
          </div>
          <div class="flex-grow-1">
            <p class='ml-2 mb-0' style='font-size: 14px; font-weight: 400; overflow-wrap: break-word; max-width: 90%'>${message}</p>
          </div>
          <div class='close-toast d-flex align-items-center' style='cursor: pointer;'>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
            </svg>
          </div>
        </div>
      `)
    $('.toast-header').append(successToast)
    removeToast(successToast)
  } else if (type === 'complete') {
    const completeToast = $(`
        <div class='top-toast toast-complete mb-1 rounded d-flex align-items-center justify-content-between' ${
          fitToText ? `style='width: auto !important; min-width:34vw'` : ``
        }>
          <div class='d-flex align-items-center' style='max-width: 90%;'>
            <svg data-description="check-circle from heroicons" xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
             <path stroke-linecap="round" stroke-linejoin="round" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
          </div>
          <div class="flex-grow-1">
            <p class='ml-2 mb-0' style='font-size: 14px; font-weight: 400; overflow-wrap: break-word; max-width: 90%'>${message}</p>
          </div>
          <div class='close-toast d-flex align-items-center' style='cursor: pointer;'>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
            </svg>
          </div>
        </div>
      `)
    $('.toast-header').append(completeToast)
    removeToast(completeToast)
  } else if (type === 'notice') {
    const noticeToast = $(`
        <div class='toast-notice top-toast mb-1 rounded d-flex align-items-center justify-content-between' ${
          fitToText ? `style='width: auto !important; min-width:34vw'` : ``
        }>
          <div class='d-flex align-items-center' style='max-width: 90%;'>
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-exclamation-triangle" viewBox="0 0 16 16">
              <path d="M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.146.146 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.163.163 0 0 1-.054.06.116.116 0 0 1-.066.017H1.146a.115.115 0 0 1-.066-.017.163.163 0 0 1-.054-.06.176.176 0 0 1 .002-.183L7.884 2.073a.147.147 0 0 1 .054-.057zm1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566z"/>
              <path d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995z"/>
            </svg>
          </div>
          <div class="flex-grow-1">
            <p class='ml-2 mb-0' style='font-size: 14px; font-weight: 400; overflow-wrap: break-word; max-width: 90%'>${message}</p>
          </div>
          <div class='d-flex align-items-center' style='cursor: pointer;'>
            ${
              action
                ? "<div class='toast-action text-dark bg-white mr-2' style='cursor:pointer !important; font-size:14px; font-weight: 400; padding: 2px 8px; border-radius: 24px; height: 24px;'>Action</div>"
                : ''
            }
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="close-toast bi bi-x" viewBox="0 0 16 16">
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
            </svg>
          </div>
        </div>
      `)
    $('.toast-header').append(noticeToast)
    if (action) noticeToast.find('.toast-action').on('click', action)
  } else if (type === 'error') {
    const errorToast = $(`
    <div class='toast-error top-toast mb-1 rounded d-flex align-items-center justify-content-between' ${
      fitToText ? `style='width: auto !important; min-width:34vw'` : ``
    }>
      <div class='d-flex align-items-center' style='max-width: 90%;'>
        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-exclamation-triangle" viewBox="0 0 16 16">
          <path d="M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.146.146 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.163.163 0 0 1-.054.06.116.116 0 0 1-.066.017H1.146a.115.115 0 0 1-.066-.017.163.163 0 0 1-.054-.06.176.176 0 0 1 .002-.183L7.884 2.073a.147.147 0 0 1 .054-.057zm1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566z"/>
          <path d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995z"/>
        </svg>
      </div>
      <div class="flex-grow-1">
            <p class='ml-2 mb-0' style='font-size: 14px; font-weight: 400; overflow-wrap: break-word; max-width: 90%'>${message}</p>
      </div>
    <div class='d-flex align-items-center' style='cursor: pointer;'>
      ${
        action
          ? "<div class='toast-action text-dark bg-white mr-2' style='cursor:pointer !important; font-size:14px; font-weight: 400; padding: 2px 8px; border-radius: 24px; height: 24px;'>Action</div>"
          : ''
      }
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="close-toast bi bi-x" viewBox="0 0 16 16">
          <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
      </svg>
    </div>
  </div>`)
    $('.toast-header').append(errorToast)
    if (action) errorToast.find('.toast-action').on('click', action)
  }
}

export function clearToasts() {
  $('.toast-header').empty()
  $('.toast-footer').empty()
}

function removeToast(element: JQuery<HTMLElement>) {
  setTimeout(() => {
    element.remove()
  }, 4000)
}

export function init() {
  $('.page-wrapper').on('click', '.close-toast', function () {
    $(this).closest('.top-toast').remove()
  })
  $('.toast-header').on('click', '.toast-action', function () {
    $(this).closest('.top-toast').remove()
  })
}

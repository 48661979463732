import classNames from 'classnames'
import { displayName } from '../../../helpers/view-helpers'
import PersonIcon from '../../icons/PersonIcon'
import SignoutIcon from '../../icons/SignoutIcon'
import currentUser from '../../utils/currentUser'
import Avatar from '../Avatar'
import NavLink from './NavLink'

type ProfileLinksProps = {
  className?: string
}

export default function ProfileLinks({ className }: ProfileLinksProps) {
  const user = currentUser()

  const handleSignout = () => {
    const form = document.getElementById('signout') as HTMLFormElement | null
    if (form) {
      form.submit()
    }
  }

  return (
    <div className="flex w-full flex-col gap-1.5 overflow-hidden rounded-md">
      <div
        className={classNames('mt-2 flex flex-row gap-2', className)}
        data-testid="user-info-popup"
      >
        <Avatar size="12" user={user} />

        <div className="p1 my-auto flex w-40 flex-col items-start text-left text-sm">
          <span className="whitespace-nowrap">{displayName(user)}</span>
          <span className="whitespace-nowrap text-xs text-deathstar">
            {user.email}
          </span>
        </div>
      </div>
      <div className="mt-2 gap-1.5 border-t-2 border-atst pt-2 pb-4">
        <NavLink
          expanded={true}
          href={`/users/${user.id}`}
          data-testid="profile-button"
          icon={PersonIcon}
          className="h-[3.75rem] items-center"
        >
          Profile
        </NavLink>
        <NavLink
          as="button"
          expanded={true}
          data-testid="signout-button"
          icon={SignoutIcon}
          onClick={handleSignout}
          className="h-[3.75rem] items-center"
        >
          Sign out
        </NavLink>
      </div>
    </div>
  )
}

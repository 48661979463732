export default function HelpIcon({ className }: { className?: string }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21 21V5C21 3.89543 20.1046 3 19 3H5C3.89543 3 3 3.89543 3 5V15.5C3 16.6046 3.89543 17.5 5 17.5H16L21 21Z"
        stroke="#3D3D3D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 12C13.7909 14.6667 10.2091 14.6667 8 12"
        stroke="#3D3D3D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

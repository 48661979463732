export default function PersonIcon({ className }: { className?: string }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M4.2588 20.0001C4.2588 20.4095 4.59065 20.7413 5 20.7413C5.40935 20.7413 5.7412 20.4095 5.7412 20.0001H4.2588ZM18.1588 20.0001C18.1588 20.4095 18.4906 20.7413 18.9 20.7413C19.3094 20.7413 19.6412 20.4095 19.6412 20.0001H18.1588ZM5.7412 20.0001C5.7412 17.9204 7.39831 16.3413 9.4 16.3413V14.8589C6.60169 14.8589 4.2588 17.0798 4.2588 20.0001H5.7412ZM9.4 16.3413H14.5V14.8589H9.4V16.3413ZM14.5 16.3413C16.5797 16.3413 18.1588 17.9984 18.1588 20.0001H19.6412C19.6412 17.2018 17.4203 14.8589 14.5 14.8589V16.3413Z"
        fill="#3D3D3D"
      />
      <path
        d="M15 5.2C16.7 6.9 16.7 9.6 15 11.2C13.3 12.8 10.6 12.9 8.99999 11.2C7.39999 9.5 7.29999 6.8 8.99999 5.2C10.7 3.6 13.3 3.6 15 5.2"
        stroke="#3D3D3D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

import { useCallback, useEffect, useState } from 'react'
import { useGetProjectsQuery } from '../../../generated/graphql'
import SearchIcon from '../../icons/SearchIcon'
import type { ProjectResult } from './AccountItem'
import AccountItem from './AccountItem'
import SkeletonLoader from '../SkeletonLoader'
import Button from '../Button'
import { XCircleIcon } from '@heroicons/react/outline'
import { debounce } from 'lodash'
import classNames from 'classnames'

const style = {
  standalone: {
    query: 'text-xl font-semibold',
    container: '',
  },
  select: {
    query: 'text-base font-normal bg-transparent',
    container: 'border border-link-500 rounded-md bg-hoth',
  },
}
type SearchHistory = {
  text?: string
  results?: ProjectResult[]
  timestamp?: number
}
const FIVE_MINS_IN_MS = 300000

type SearchAccountComponentProps = {
  onCancelSearch?: () => void
  onSelectItem?: (p: ProjectResult) => void
  variant?: 'standalone' | 'select'
  hideResultDelimiter?: boolean
}

export function SearchAccountComponent({
  onCancelSearch,
  onSelectItem,
  variant = 'standalone',
  hideResultDelimiter = false,
}: SearchAccountComponentProps) {
  const pastHistory = JSON.parse(
    window.localStorage.getItem('searchAccount.tmp') ?? '{}'
  )

  const [queryField, setQueryField] = useState<string>()
  const [query, setQuery] = useState<string | null>(null)
  const [recent, setRecent] = useState<SearchHistory>(pastHistory)

  const [containerRef, setContainerRef] = useState<HTMLDivElement | null>(null)
  const handleSetContainerRef = useCallback((node: HTMLDivElement) => {
    setContainerRef(node)
  }, [])

  const handleListHeight = useCallback(() => {
    if (!containerRef) return

    const screenHeight = window.innerHeight
    const elementTop = containerRef.getBoundingClientRect().top
    const mb = 8

    containerRef.style.maxHeight = `${screenHeight - elementTop - mb}px`
  }, [containerRef])

  useEffect(() => {
    handleListHeight()

    const debouncedHandleListHeight = debounce(handleListHeight, 200)
    window.addEventListener('resize', debouncedHandleListHeight)
    return () => {
      window.removeEventListener('resize', debouncedHandleListHeight)
    }
  }, [handleListHeight])

  const [{ data, fetching }] = useGetProjectsQuery({
    variables: {
      args: {
        search: query,
      },
    },
    pause: !query,
  })

  useEffect(() => {
    const tid = setTimeout(() => setQuery(queryField ?? ''), 400)

    return () => clearTimeout(tid)
  }, [queryField])

  useEffect(() => {
    if (variant === 'select') return
    if (query === '' && recent.timestamp != null) {
      const tempData = {
        text: undefined,
        timestamp: undefined,
        results: undefined,
      }
      setRecent(tempData)
      window.localStorage.setItem(`searchAccount.tmp`, JSON.stringify(tempData))
    }

    if (!query || !data?.projects) return
    if (query === recent.text) return

    const tempData = {
      text: query,
      timestamp: Date.now(),
      results: data?.projects,
    }
    setRecent(tempData)
    window.localStorage.setItem('searchAccount.tmp', JSON.stringify(tempData))
  }, [query, recent, setRecent, data?.projects, variant])

  useEffect(() => {
    if (variant === 'select') return
    if (queryField === undefined) {
      setQueryField(
        (recent.timestamp ?? 0) + FIVE_MINS_IN_MS > Date.now()
          ? recent.text
          : undefined
      )
    }
  }, [queryField, recent, variant])

  const resultData =
    data?.projects ??
    (recent.timestamp && recent.timestamp > Date.now() - FIVE_MINS_IN_MS
      ? recent.results
      : [])

  const results =
    fetching ||
    // there are 1-2 render cyles where the queryField is set
    // but the query string is not set yet so it would flash
    // No Results. So the following checks ensure that the flash
    // doesn't happen
    (queryField != null &&
      queryField.length > 0 &&
      (query == null || query.length === 0)) ? (
      <SkeletonLoader />
    ) : queryField && queryField.length > 0 ? (
      resultData?.length ? (
        resultData.map((p, i) => (
          <AccountItem
            account={p}
            key={p.id}
            showDelimiter={i !== 0 && !hideResultDelimiter}
            query={query}
            variant={variant}
            onClick={onSelectItem}
          />
        ))
      ) : (
        <div className="p-4 text-xl font-semibold text-deathstar">
          No Results
        </div>
      )
    ) : null

  return (
    <>
      <div className="flex w-full flex-col items-center sm:hidden">
        <div className="flex w-full items-center pb-2">
          <div className="ml-3 flex h-12 w-full items-center gap-3 rounded-md border-[0.09375rem] border-link-500 bg-hoth !p-3 xl:w-[17rem] xl:border-atat">
            <SearchIcon className="h-5 w-5 stroke-deathstar" />
            <input
              className="w-full flex-1 border-0 bg-transparent p-0 !ring-0 placeholder-shown:placeholder-[#bebebe]"
              type="text"
              value={
                queryField ??
                (recent.timestamp &&
                recent.timestamp > Date.now() - FIVE_MINS_IN_MS &&
                variant !== 'select'
                  ? recent.text
                  : '')
              }
              onChange={(e) => setQueryField(e.target.value)}
              placeholder="Search accounts"
              autoFocus
            />
            {queryField?.length ? (
              <Button
                variant="bareMinimal"
                className="!p-0"
                hideRing={true}
                leadingIcon={({ className: _, props }) => (
                  <XCircleIcon className="h-6 w-6 text-gonk" {...props} />
                )}
                onClick={() => setQueryField('')}
              />
            ) : null}
          </div>
          <div className="px-4">
            <Button
              variant="bareMinimal"
              className="w-12 text-link-500"
              hideRing={true}
              data-testid="close-nav-search-on-mobile"
              onClick={() => onCancelSearch?.()}
            >
              Cancel
            </Button>
          </div>
        </div>
        {results != null ? (
          <div
            ref={handleSetContainerRef}
            className="mb-2 max-h-[calc(100vh-132px)] w-full overflow-y-auto"
          >
            {results}
          </div>
        ) : null}
      </div>
      <div className="hidden h-fit flex-col gap-1 sm:flex">
        <div
          className={classNames(
            style[variant].container,
            'flex h-[2.875rem] items-center gap-3 px-4'
          )}
        >
          <SearchIcon className="h-6 w-6 stroke-deathstar" />
          <input
            data-testid="search-account-input"
            value={
              queryField ??
              (recent.timestamp &&
              recent.timestamp > Date.now() - FIVE_MINS_IN_MS &&
              variant !== 'select'
                ? recent.text
                : '')
            }
            onChange={(e) => setQueryField(e.target.value)}
            className={classNames(
              style[variant].query,
              '-mt-0.5 flex-grow placeholder-shown:placeholder-[#bebebe]'
            )}
            placeholder="Search accounts"
          />
          {queryField && queryField.length > 0 && (
            <div
              className="w-fit flex-none cursor-pointer"
              onClick={() => setQueryField('')}
            >
              Clear
            </div>
          )}
        </div>
        {results != null && (
          <div className="mb-2 max-h-[360px] overflow-y-auto border-t-2 border-atst">
            {results}
          </div>
        )}
      </div>
    </>
  )
}

import { Dialog, Transition } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/outline'
import classNames from 'classnames'
import { Fragment } from 'react'
import AppButton from './AppButton'
import NavLink from './NavLink'
import ProfileLinks from './ProfileLinks'
import type { ApplicationName } from './utils'
import { APPS, LINKS, APPICONS, isAppVisible } from './utils'

type AppSwitcherMobileProps = {
  selectedAppName: ApplicationName
  showDialog: boolean
  onClose: () => void
}

export default function AppSwitcherMobile({
  selectedAppName,
  showDialog,
  onClose,
}: AppSwitcherMobileProps) {
  const optionClasses = (isSelected: boolean) =>
    classNames(
      'flex w-full h-[3.75rem] !px-3 flex-row items-center !rounded-lg',
      isSelected ? 'bg-atst' : ''
    )

  return (
    <Transition.Root show={showDialog} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-[10010] overflow-hidden"
        onClose={onClose}
        data-testid="appswitcher-mobile"
      >
        <div className="absolute inset-0 overflow-hidden">
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay
              data-testid="backdrop-app-switcher-mobile"
              className="absolute inset-0 bg-black bg-opacity-30 transition-opacity"
            />
          </Transition.Child>

          <div className="pointer-events-none fixed inset-y-0 left-0 flex max-w-[18rem]">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-[-18rem]"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-[-18rem]"
            >
              <div className="pointer-events-auto w-screen max-w-full">
                <div className="flex h-full flex-col !bg-white !shadow-xl">
                  <div className="flex grow flex-col overflow-y-auto">
                    <div className="relative !mt-4 flex flex-col !gap-1.5 !px-3">
                      {APPS.filter(isAppVisible).map((app) => (
                        <NavLink
                          className={optionClasses(app === selectedAppName)}
                          href={LINKS[app].landingPage}
                          key={app}
                          expanded
                          useChildWrapper={false}
                        >
                          <AppButton
                            name={app}
                            data-testid={`appswitcher-${app}`}
                            expanded
                            className="items-center justify-start"
                            icon={APPICONS[app]}
                            titleIcon={
                              app === selectedAppName ? (
                                <CheckIcon className="my-auto h-full w-8 stroke-gonk stroke-1" />
                              ) : undefined
                            }
                            useChildWrapper={false}
                          />
                        </NavLink>
                      ))}
                      <div className="!px-3">
                        <ProfileLinks className="border-t-2 border-atst !pt-2" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default function SalesRabbitIcon({ className }: { className?: string }) {
  return (
    <svg
      width="33"
      height="48"
      viewBox="0 0 33 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M30.7117 0C30.5948 0.00197612 30.4773 0.0161383 30.3614 0.042816C27.0223 0.814491 23.9947 2.70597 21.8352 5.36879C19.6418 8.07344 18.4337 11.4786 18.4337 14.9563C18.4337 18.4336 19.6418 21.8388 21.8352 24.5434C22.7952 25.7271 23.9273 26.7573 25.1812 27.6057C21.348 29.0183 18.2042 31.8485 16.4004 35.4506C14.5955 31.8485 11.452 29.0183 7.6188 27.6057C8.87307 26.7573 10.0051 25.7271 10.9649 24.5434C13.1582 21.8388 14.3664 18.4336 14.3664 14.9563C14.3664 11.4786 13.1582 8.07344 10.9649 5.36879C8.80566 2.70597 5.77775 0.814491 2.43867 0.042816C2.32211 0.015809 2.20389 0.00164677 2.08633 0H2.03884C1.67522 0.0049403 1.31922 0.128118 1.02865 0.357019C0.631485 0.669575 0.400024 1.14483 0.400024 1.64775V31.9292C0.400024 40.6794 7.57729 47.7981 16.4004 47.7981C25.2224 47.7981 32.4 40.6794 32.4 31.9292V1.64775C32.4 1.14483 32.1686 0.669575 31.7714 0.357019C31.4818 0.128777 31.1268 0.00559901 30.7642 0H30.7117ZM21.7568 14.9563C21.7568 10.0611 24.6791 5.77719 29.0769 3.91371V25.9985C24.6791 24.135 21.7568 19.8508 21.7568 14.9563ZM3.72316 3.91371C8.12091 5.77719 11.0432 10.0611 11.0432 14.9563C11.0432 19.8508 8.12091 24.135 3.72316 25.9985V3.91371ZM18.0618 42.4863C18.0618 36.1126 22.87 30.8354 29.0769 30.0252V31.9292C29.0769 38.3031 24.269 43.5807 18.0618 44.3906V42.4863ZM3.72316 31.9292V30.0252C9.93009 30.8354 14.7386 36.1126 14.7386 42.4863V44.3906C8.53103 43.5807 3.72316 38.3031 3.72316 31.9292Z"
        fill="white"
      />
    </svg>
  )
}

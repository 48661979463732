export default function CoursesIcon({ className }: { className: string }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.896 14H5.5C4.67157 14 4 13.3284 4 12.5V5C4 3.89543 4.89543 3 6 3H10"
        stroke="#323232"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.9988 21H5.00072C4.37324 21 3.78215 20.7055 3.40427 20.2045C3.02639 19.7036 2.90556 19.0543 3.07791 18.451L4.14222 14.7253C4.26487 14.296 4.65728 14 5.10378 14H18.8958C19.3423 14 19.7347 14.296 19.8573 14.7253L20.9216 18.451C21.094 19.0543 20.9732 19.7036 20.5953 20.2045C20.2174 20.7055 19.6263 21 18.9988 21Z"
        stroke="#323232"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 18H14"
        stroke="#323232"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.2002 6H10.2002"
        stroke="#323232"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.2002 9H10.2002"
        stroke="#323232"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.0249 6.00342V8.25277C15.0249 8.76412 15.3138 9.23158 15.7712 9.46026L16.2701 9.70971C17.0443 10.0968 17.9555 10.0968 18.7297 9.70971L19.2286 9.46026C19.686 9.23158 19.9749 8.76412 19.9749 8.25277V6.00342"
        stroke="#323232"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.4057 5.16627L16.4668 6.74871C17.1149 7.08373 17.8852 7.08373 18.5333 6.74871L21.5945 5.16627C21.8436 5.0375 22.0001 4.78046 22.0001 4.49999C22.0001 4.21951 21.8436 3.96247 21.5945 3.8337L18.5333 2.25126C17.8852 1.91625 17.1149 1.91625 16.4668 2.25126L13.4057 3.8337C13.1565 3.96247 13 4.21951 13 4.49999C13 4.78046 13.1565 5.0375 13.4057 5.16627Z"
        stroke="#323232"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22 4.51025V6.24725"
        stroke="#323232"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

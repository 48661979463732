import { useEffect, useMemo, useState } from 'react'
import type { Attachment } from '../../../../../../generated/graphql'
import GenericDocumentIcon from '../../../../../icons/GenericDocumentIcon'
import Spinner from '../../../../../components/Spinner'
import { getFileExtensionFromUrl } from '.'
import { convertHeicToJpeg } from '../../../../../../helpers/convertHeicToJpeg'

type TaskFileAttachmentProps = {
  attachment: Pick<Attachment, 'id' | 'name' | 'url'>
  className?: string
  containerClassName?: string
}

const TaskFileAttachment = ({
  attachment,
  className,
  containerClassName,
}: TaskFileAttachmentProps) => {
  const [image, setImage] = useState<
    | {
        url: string
        loading: boolean
      }
    | undefined
  >(undefined)

  const fileType = useMemo(() => {
    return getFileExtensionFromUrl(attachment.url)
  }, [attachment.url])

  useEffect(() => {
    if (
      fileType?.toLowerCase().match(/(png|webp|svg|jpg|jpeg|pjpeg|avif|pjp)$/)
    ) {
      setImage({ url: attachment.url, loading: false })
    }

    if (fileType?.toLowerCase().match(/(heic)$/)) {
      setImage({ url: '', loading: true })
      convertHeicToJpeg(fetch(attachment.url).then((res) => res.blob()))
        .then((url) => setImage({ url, loading: false }))
        .catch(() => setImage(undefined))
    }
  }, [attachment.url, fileType])

  return (
    <div
      className={
        containerClassName ||
        'flex h-12 w-12 min-w-[3rem] items-center justify-center overflow-hidden rounded-md bg-atst'
      }
    >
      {image ? (
        image.loading ? (
          <Spinner
            data-testid={`attachment-loading-${attachment.id}`}
            size="tiny"
          />
        ) : (
          <img
            src={image.url}
            alt="Attachment thumbnail"
            className={className || 'h-12 w-12'}
          />
        )
      ) : (
        <div>
          <GenericDocumentIcon />
          <span className="sr-only">Document attachment</span>
        </div>
      )}
    </div>
  )
}

export default TaskFileAttachment

export default function LibraryIcon({ className }: { className?: string }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M14.025 16.9041V19.2421C14.0249 19.7532 14.3138 20.2206 14.771 20.4491L15.293 20.7101C16.0529 21.0898 16.9472 21.0898 17.707 20.7101L18.229 20.4491C18.6863 20.2206 18.9751 19.7532 18.975 19.2421V16.9041M21.0002 15.4421V17.2429M19 10.9951V4.99512C19 3.89055 18.1046 2.99512 17 2.99512H5C3.89543 2.99512 3 3.89055 3 4.99512V17.9951M3 17.9951C3 16.8905 3.89543 15.9951 5 15.9951H9M3 17.9951C3 19.0997 3.89543 19.9951 5 19.9951H11M7.00021 2.99512V15.9951M12.413 16.1111L15.5 17.6531C16.1315 17.9681 16.8744 17.9681 17.506 17.6531L20.59 16.1111C20.8434 15.9847 21.0036 15.7258 21.0036 15.4426C21.0036 15.1594 20.8434 14.9005 20.59 14.7741L17.5 13.2321C16.8686 12.9161 16.1253 12.9161 15.494 13.2321L12.41 14.7741C12.1568 14.9011 11.9972 15.1603 11.9978 15.4435C11.9984 15.7268 12.1592 15.9853 12.413 16.1111Z"
        stroke="#3D3D3D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

import { DateTime } from 'luxon'

export const holidays = [
  // 2023
  DateTime.fromFormat('11-23-2023', 'MM-dd-yyyy').startOf('day'), // Thanksgiving Day
  DateTime.fromFormat('11-24-2023', 'MM-dd-yyyy').startOf('day'), // Day After Thanksgiving
  DateTime.fromFormat('12-25-2023', 'MM-dd-yyyy').startOf('day'), // Christmas Day
  DateTime.fromFormat('12-26-2023', 'MM-dd-yyyy').startOf('day'), // Christmas Eve (Observed)

  // 2024
  DateTime.fromFormat('01-01-2024', 'MM-dd-yyyy').startOf('day'), // New Year's Day
  DateTime.fromFormat('01-15-2024', 'MM-dd-yyyy').startOf('day'), // Martin Luther King Jr. Day
  DateTime.fromFormat('02-19-2024', 'MM-dd-yyyy').startOf('day'), // President's Day
  DateTime.fromFormat('05-27-2024', 'MM-dd-yyyy').startOf('day'), // Memorial Day
  DateTime.fromFormat('06-19-2024', 'MM-dd-yyyy').startOf('day'), // Juneteenth
  DateTime.fromFormat('07-04-2024', 'MM-dd-yyyy').startOf('day'), // Independence Day
  DateTime.fromFormat('09-02-2024', 'MM-dd-yyyy').startOf('day'), // Labor Day
  DateTime.fromFormat('11-28-2024', 'MM-dd-yyyy').startOf('day'), // Thanksgiving Day
  DateTime.fromFormat('11-29-2024', 'MM-dd-yyyy').startOf('day'), // Day After Thanksgiving
  DateTime.fromFormat('12-24-2024', 'MM-dd-yyyy').startOf('day'), // Christmas Eve
  DateTime.fromFormat('12-25-2024', 'MM-dd-yyyy').startOf('day'), // Christmas Day
]

import { isArray } from 'lodash'

/** Converts an heic image to jpeg
 * @param blob the blob of the heic image. Note that this can be a promise to get
 *        a blob so that you can fetch that in parallel while this is doing the
 *        dynamic `import(` to get heic2any
 * @returns a promise that resolves to the url of the converted image
 **/
export async function convertHeicToJpeg(blob: Blob | Promise<Blob>) {
  const heic2any = (await import('heic2any')).default
  const conversionResult = await heic2any({
    blob: await blob,
    toType: 'image/jpeg',
  })
  const createdUrl = URL.createObjectURL(
    isArray(conversionResult) ? conversionResult[0] : conversionResult
  )
  return createdUrl
}

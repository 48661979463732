import { createClient, dedupExchange, fetchExchange } from 'urql'
import { cacheExchange } from '@urql/exchange-graphcache'
import type { Cache } from '@urql/exchange-graphcache'
import { devtoolsExchange } from '@urql/devtools'
import type {
  Mutation,
  MutationCreate_CommentArgs,
  MutationCreate_Or_Update_EventArgs,
  MutationCreate_OrganizationArgs,
  MutationUpdate_CaseArgs,
  Project,
  UpdateRecruitMutation,
  UpdateRecruitMutationVariables,
} from '../../generated/graphql'
import {
  INACTIVE_PROGRESSION_STATUS,
  PROGRESSION_STATUSES,
} from '../views/Recruit/constants'

export const GRAPHQL_API_URL = `${window.ENV.API_URL}/v1/solflow/graphql`

function invalidateProjectField(
  projectId: Project['id'],
  field: keyof Project,
  cache: Cache
) {
  cache.invalidate({ __typename: 'Project', id: projectId }, field)
}

const clientCache = cacheExchange({
  updates: {
    Mutation: {
      create_recruit(_, __, cache) {
        // Re-fetch the recruits query when the mutation completes
        cache.invalidate('Query', 'recruits', {
          exclude_progression_statuses: [INACTIVE_PROGRESSION_STATUS.value],
        })
      },
      update_recruit(
        result: UpdateRecruitMutation,
        args: UpdateRecruitMutationVariables,
        cache
      ) {
        const newStatus = args.input?.progression_status
        // Invalidate both inactive & active caches when status is updated to
        // initial or inactive, in case a recruit is being deactivated or
        // re-activated and needs to be moved from one cache to another
        if (
          newStatus &&
          [
            PROGRESSION_STATUSES[0].value,
            INACTIVE_PROGRESSION_STATUS.value,
          ].includes(newStatus)
        ) {
          cache.invalidate('Query', 'recruits', {
            exclude_progression_statuses: [INACTIVE_PROGRESSION_STATUS.value],
          })
          cache.invalidate('Query', 'recruits', {
            include_progression_statuses: [INACTIVE_PROGRESSION_STATUS.value],
          })
        }
        // Also return the result of the mutation so that updates that don't
        // deactivate / re-active recruits get merged into the cache automatically
        return result
      },
      upsert_user_info(_, { user_id }, cache) {
        // Re-fetch onboarding user info when user_info is updated
        cache.invalidate('Query', 'user', {
          id: user_id,
        })
      },
      register_for_individual_competition(_, { competition_id }, cache) {
        // Re-fetch competition info when a participant is added
        cache.invalidate('Query', 'competition', {
          id: competition_id,
        })
      },
      create_organization(_, args: MutationCreate_OrganizationArgs, cache) {
        cache.invalidate({
          __typename: 'Organization',
          id: args.input.parent_id,
        })
        cache.invalidate('Query', 'organizations')
      },

      create_comment(result, args: MutationCreate_CommentArgs, cache) {
        invalidateProjectField(args.input.project_id, 'comments', cache)
      },
      delete_comment(result: Pick<Mutation, 'delete_comment'>, args, cache) {
        invalidateProjectField(
          result.delete_comment.project_id,
          'comments',
          cache
        )
      },
      create_or_update_event(
        result,
        args: MutationCreate_Or_Update_EventArgs,
        cache
      ) {
        if (args.input.project_id) {
          invalidateProjectField(args.input.project_id, 'events', cache)
        }
      },
      update_case(
        result: Pick<Mutation, 'update_case'>,
        args: MutationUpdate_CaseArgs,
        cache
      ) {
        const projectId = result.update_case.project_id
        if (args.input.solflow_status != null && projectId != null) {
          invalidateProjectField(projectId, 'global_status', cache)
          invalidateProjectField(projectId, 'dispositions', cache)
        }
        return result
      },
    },
  },
  keys: {
    Stats: () => null,
    TeamStats: () => null,
    IndividualMatchParticipant: () => null,
    TeamMatchParticipant: () => null,
    CompetitionDates: () => null,
    PartialDateRange: () => null,
    Salesforce: () => null,
    SalesforceLead: (data) => data.Id as string,
  },
})

const client = createClient({
  url: GRAPHQL_API_URL,
  fetchOptions: () => {
    return window.ENV.CURRENT_USER_JWT
      ? {
          headers: {
            authorization: `Bearer ${window.ENV.CURRENT_USER_JWT}`,
          },
        }
      : {}
  },
  exchanges: [devtoolsExchange, dedupExchange, clientCache, fetchExchange],
  maskTypename: true,
})

export default client

export default function KitHomeIcon({ className }: { className?: string }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M3 11.6906L10.9351 4.88902C11.5479 4.36374 12.4521 4.36374 13.0649 4.88902L21 11.6906"
        stroke="#3D3D3D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20 20.995V4.495C20 4.21885 19.7761 3.995 19.5 3.995H16.5C16.2239 3.995 16 4.21885 16 4.495V7.10099"
        stroke="#3D3D3D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.54541 20.995V15.495C9.54541 14.9427 9.99313 14.495 10.5454 14.495H13.4545C14.0068 14.495 14.4545 14.9427 14.4545 15.495V20.995"
        stroke="#3D3D3D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.28573 10.5887V20.995"
        stroke="#3D3D3D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21 20.9952H3"
        stroke="#3D3D3D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

function loadZoomScript() {
  const isMobile = window.innerHeight < 700 || window.innerWidth < 900
  const desktopScriptId = 'zoom-script-desktop'
  let desktopScript = document.getElementById(desktopScriptId)

  const alternativeDesktopScriptId = 'zoom-script-alternative-desktop'
  let alternativeDesktopScript = document.getElementById(
    alternativeDesktopScriptId
  )
  const isAlternativeDesktopVisible =
    window.innerHeight <= 800 && window.innerWidth > 900

  const mobileScriptId = 'zoom-script-mobile'
  const mobileScript = document.getElementById(mobileScriptId)

  if (isMobile && (desktopScript != null || alternativeDesktopScript != null)) {
    desktopScript?.remove()
    alternativeDesktopScript?.remove()

    desktopScript = null
    alternativeDesktopScript = null
  } else if (!isMobile && mobileScript != null) {
    mobileScript.remove()
  } else if (isAlternativeDesktopVisible && desktopScript != null) {
    desktopScript.remove()
    desktopScript = null
  } else if (!isAlternativeDesktopVisible && alternativeDesktopScript != null) {
    alternativeDesktopScript.remove()
    alternativeDesktopScript = null
  }

  if (
    (isMobile && mobileScript == null) ||
    (!isMobile && desktopScript == null)
  ) {
    const script = window.document.createElement('script')
    script.id = isMobile
      ? mobileScriptId
      : isAlternativeDesktopVisible
        ? alternativeDesktopScriptId
        : desktopScriptId
    script.src = 'https://us01ccistatic.zoom.us/us01cci/web-sdk/chat-client.js'
    script.dataset.chatEntryId = '_so2HfD-Tz626S3-SUVRVA'
    script.dataset.env = 'us01'
    script.dataset.apikey = 'k8cIRsynRDOrRmnLkygH2A'

    script.dataset.el = isMobile
      ? '#zoom-chat-mobile'
      : isAlternativeDesktopVisible
        ? '#zoom-chat-short-screen'
        : '#zoom-chat-desktop'

    // there is a little delay before the script can be attached to the button
    setTimeout(() => {
      window.document.head.appendChild(script)
    }, 500)
  }
}

/**
 * The Zoom chat script can only be attached to a single button for proper functionality.
 * Therefore, we need to handle registration based on whether the device is desktop or mobile.
 * Additionally, the help buttons for both desktop and mobile are not considered a single element,
 * so we must re-register the script whenever the screen size changes significantly enough to switch between
 * desktop and mobile layouts.
 */
let registered = false
export function loadZoom() {
  if (registered) return
  registered = true
  window.addEventListener('resize', loadZoomScript)
  loadZoomScript()
}

export default function OrganizationIcon({
  className,
}: {
  className?: string
}) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 12H5C3.895 12 3 11.105 3 10V5C3 3.895 3.895 3 5 3H10C11.105 3 12 3.895 12 5V10C12 11.105 11.105 12 10 12Z"
        stroke="#3D3D3D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.5 20H15.5C14.672 20 14 19.328 14 18.5V15.5C14 14.672 14.672 14 15.5 14H18.5C19.328 14 20 14.672 20 15.5V18.5C20 19.328 19.328 20 18.5 20Z"
        stroke="#3D3D3D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.5 11H16.5C15.672 11 15 10.328 15 9.5V6.5C15 5.672 15.672 5 16.5 5H19.5C20.328 5 21 5.672 21 6.5V9.5C21 10.328 20.328 11 19.5 11Z"
        stroke="#3D3D3D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.5 21H6.5C5.672 21 5 20.328 5 19.5V16.5C5 15.672 5.672 15 6.5 15H9.5C10.328 15 11 15.672 11 16.5V19.5C11 20.328 10.328 21 9.5 21Z"
        stroke="#3D3D3D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

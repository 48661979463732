import classNames from 'classnames'

const SIZE_CLASSES = {
  tiny: 'w-5 h-5 border-2',
  small: 'w-7 h-7 border-2',
  large: 'w-20 h-20 border-4',
}

type SpinnerProps = {
  size?: keyof typeof SIZE_CLASSES
  text?: string
  'data-testid'?: string
  className?: string
  borderColor?: string
}

export default function Spinner({
  size = 'small',
  text = 'Loading...',
  'data-testid': testId,
  className = '',
  borderColor = '',
}: SpinnerProps) {
  return (
    <div
      data-testid={testId}
      className={classNames('flex items-center justify-center', className)}
    >
      <div
        className={classNames(
          'inline-block animate-spin rounded-full',
          'border-b-transparent',
          borderColor ? borderColor : 'border-current',
          SIZE_CLASSES[size]
        )}
        role="status"
      >
        <span className="sr-only">{text}</span>
      </div>
    </div>
  )
}

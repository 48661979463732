import type { IMessage } from '@novu/shared'
import classNames from 'classnames'
import DOMPurify from 'dompurify'
import ReactHTMLParser from 'react-html-parser'
import TimeAgoPosts from '../../TimeAgoPosts'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useMarkNotificationsAs } from '@novu/notification-center'

type NotificationListItemProps = {
  notification: IMessage
}

enum NotificationTypesToMarkAsRead {
  OpsFlow = 'ops-flow-notification',
  AccountCancelled = 'account-cancelled',
}

const NotificationListItem = ({ notification }: NotificationListItemProps) => {
  const itemRef = useRef<HTMLDivElement>(null)
  const [isVisible, setIsVisible] = useState(false)
  const { markNotificationsAs } = useMarkNotificationsAs()

  useEffect(() => {
    if (isVisible || notification.seen) {
      return
    }

    const checkIfElementIsInViewport = () => {
      const rect = itemRef.current?.getBoundingClientRect()
      if (!rect) {
        return
      }

      setIsVisible(
        rect.top >= 0 &&
          rect.left >= 0 &&
          rect.bottom <=
            (window.innerHeight || document.documentElement.clientHeight) &&
          rect.right <=
            (window.innerWidth || document.documentElement.clientWidth)
      )
    }

    const interval = setInterval(() => {
      checkIfElementIsInViewport()
    }, 1000)

    return () => {
      clearInterval(interval)
    }
  }, [isVisible, notification])

  const markNotificationAsSeen = useCallback(() => {
    if (!isVisible || notification.seen) {
      return
    }

    const notificationType = notification.templateIdentifier
    if (notificationType == null) {
      return
    }

    if (
      Object.values(NotificationTypesToMarkAsRead).includes(
        notificationType as NotificationTypesToMarkAsRead
      )
    ) {
      markNotificationsAs({
        messageId: notification._id,
        seen: true,
        read: notification.read,
      })
    }
  }, [isVisible, notification, markNotificationsAs])

  useEffect(() => {
    // mark the notification as readed after 5 seconds if it's visible
    // and the user didn't move the mouse over it
    const interval = setTimeout(() => {
      markNotificationAsSeen()
    }, 5000)

    return () => {
      clearTimeout(interval)
    }
  }, [markNotificationAsSeen])

  return (
    <div
      className="border-b-2 p-3 hover:bg-hoth"
      ref={itemRef}
      onMouseEnter={markNotificationAsSeen}
    >
      <a
        href={notification.cta?.data?.url}
        className="flex cursor-pointer items-start"
      >
        <div
          className={
            'mr-1 flex h-9 w-9 items-center justify-center rounded-md bg-vader text-center text-lg text-snowtrooper'
          }
        >
          S
        </div>
        <div className="flex-1 px-3">
          <div className="text-sm font-normal">
            {ReactHTMLParser(
              DOMPurify.sanitize(notification.content as string)
            )}
          </div>
          <div className="flex items-center font-normal text-deathstar">
            <TimeAgoPosts date={new Date(notification.createdAt)} />
            <div className="m-1 h-1 w-1 rounded-full bg-deathstar"></div>
          </div>
        </div>
        <div className="justify-end">
          <div className={classNames('m-2 h-2 w-2')}>
            <div
              data-testid="notification-unseen-indicator"
              className={classNames('rounded-full bg-error h-full w-full', {
                hidden: notification.seen,
              })}
            ></div>
          </div>
        </div>
      </a>
    </div>
  )
}

export default NotificationListItem

import { useContext } from 'react'
import classNames from 'classnames'

import Button from '../Button'
import CirclePlus from '../../icons/CirclePlusIcon'
import { hasRole, Role } from '../../utils/currentUser'
import NavContext from '../SideNavigation/NavContext'

type GlobalAddNewProps = {
  expanded?: boolean
  isMobile?: boolean
  onClick?: () => void
}

export default function GlobalAddNew({
  expanded = false,
  isMobile = false,
  onClick,
}: GlobalAddNewProps) {
  const { setAddNewOpen, setLeadCreationOpen } = useContext(NavContext)

  const canAddUsersAndOrgs = hasRole(Role.Superadmin)

  return (
    <div
      className={classNames('flex items-center justify-center', {
        'px-[6px]': !isMobile,
      })}
    >
      <Button
        data-testid="add-new"
        rounded="pill"
        className={'w-full !justify-start px-[10px] !font-normal'}
        size="large"
        onClick={() => {
          if (canAddUsersAndOrgs) {
            setAddNewOpen(true)
          } else {
            setLeadCreationOpen(true)
          }
          onClick?.()
        }}
        leadingIconClassname="!mr-5"
      >
        <CirclePlus className="mr-[21px]" />
        {expanded && (canAddUsersAndOrgs ? 'Add New' : 'New Lead')}
      </Button>
    </div>
  )
}

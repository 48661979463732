import { useState } from 'react'
import { useAgreeAnnouncementMutation } from '../../../generated/graphql'
import Modal from '../../components/Modal'
import currentUser from '../../utils/currentUser'
import AnnouncementItem from './AnnouncementItem'

export default function AnnouncementAgreementModal() {
  const [isLoading, setIsLoading] = useState(false)

  const user = currentUser()
  const announcement = user.announcement_agreement?.announcement

  const [, agreeAnnouncement] = useAgreeAnnouncementMutation()

  if (!announcement) {
    return null
  }

  return (
    <Modal
      modalOpen
      isLoading={isLoading}
      hideCancelButton
      hideCloseButton
      paddingClasses="p-6"
      body={
        <div className="[&_h2]:!text-2xl [&_h2+div]:!text-base">
          <AnnouncementItem announcement={announcement} />
        </div>
      }
      orientationButtons="horizontal"
      colorButtonConfirm="primary"
      textConfirm="Accept and Continue"
      onConfirm={async () => {
        setIsLoading(true)
        await agreeAnnouncement({ id: user.announcement_agreement!.id })
        // Since the user may have multiple agreements pending per agreement
        // reloading the page we can ensure that the modal will open again with the new one
        window.location.reload()
        return true
      }}
    />
  )
}
